import { Component, OnInit, DoCheck, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AgropolosMatContainerService } from '../../../components/agropolos-container/agropolos-mat-container.service';
import { AgropolosMatSidenavContainerService } from './agropolos-mat-sidenav-container.service';
import { LocalStorageService } from '../../../../core/local-storage.service';

@Component({
  selector: 'agropolos-sidenav-container',
  templateUrl: './agropolos-mat-sidenav-container.component.html',
  styleUrls: ['./agropolos-mat-sidenav-container.component.scss']
})
export class AgropolosMatSidenavContainerComponent implements OnInit, DoCheck {

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  @Input() mode: string;

  private matches: boolean;

  constructor(private sepMatContainerService: AgropolosMatContainerService,
    private localStorageService: LocalStorageService,
    private sepMatSidenavContainerService: AgropolosMatSidenavContainerService) { }

  ngOnInit() {

    this.sepMatSidenavContainerService.emitMode.subscribe(mode => {
      this.mode = mode;
    });

    this.mainSideNav.toggle(this.localStorageService.getObject('navbar-fixed'));

    this.sepMatSidenavContainerService.emitToggle.subscribe(isOpen => {
      this.mainSideNav.toggle(isOpen);
    });


  }

  ngDoCheck() {
    this.sepMatContainerService.emitMatches.subscribe(matches => this.matches = matches);
  }

  isMatched(): boolean {
    return this.matches;
  }

  getMode() {
    return (this.mode !== undefined ? this.mode : (this.isMatched() ? 'over' : 'side'));
  }

}
