import { Injectable } from '@angular/core';
import { GridSettings } from '../../models/GridSettings';

@Injectable()
export class Data {

    public storage: any;
    public gridSettings: GridSettings;
    public entityFilter: any;
    public dados: any;

    public constructor() { }

}
