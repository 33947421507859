import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ForgotPasswordService {

  private apiUrl = '';

  constructor(private http: HttpClient) { 
    this.apiUrl = environment.resetPassUrl;
  }

  forgotpassword(email: string) {
    return this.http.get<any>(`${this.apiUrl}/manage/forgotpassword?email=` + email);
  }
}