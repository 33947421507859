import { NgModule } from '@angular/core';
import { MaterialModulos } from '../../../material-modulos.module';
import { AgropolosMaterialModule } from '../../agropolos-material.module';
import { VisualizaAssinaturaRoutingModule } from './viaualizar-assinatura-routing.module';
import { VisualizaAssinaturaComponent } from './viaualizar-assinatura.component';
import { VisualizaAssinaturaService } from './viaualizar-assinatura.service';

@NgModule({
  imports: [AgropolosMaterialModule, VisualizaAssinaturaRoutingModule, MaterialModulos],
  declarations: [VisualizaAssinaturaComponent],
  exports: [VisualizaAssinaturaComponent],
  providers: [VisualizaAssinaturaService]
})
export class VisualizaAssinaturaModule { }
