import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class AgropolosMatToolbarService {

  emitChangedColor = new EventEmitter<string>();
  emitAppTitle = new EventEmitter<string>();

  constructor() { }

  changeColor(color: string){
    this.emitChangedColor.emit(color);
  }

  changeAppTitle(appTitle: string){
    this.emitAppTitle.emit(appTitle);
  }
}
