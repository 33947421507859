import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[maskCpfCnpj]' })
export class MaskCpfCnpjDirective {

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPressEnter: EventEmitter<any> = new EventEmitter();

  arrayNumber: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  arrayFunction: any[] = [, 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

  public el: HTMLElement;
  constructor(public element: ElementRef) {
    this.el = this.element.nativeElement;
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const htlmElement = (<HTMLInputElement>this.el);
    if (event.key === 'Enter') {
      this.onPressEnter.emit();
    } else if (this.arrayFunction.indexOf(event.key) < 0) {
      htlmElement.value = this.convertToCpfCnpj(htlmElement.value);
    }
  }

  private convertToCpfCnpj(num) {
    if (num) {
      num = num.toString();
      num = num.replace(/\D/g, '');
      const aux: number = num.length;

      switch (true) {
        case aux === 4:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 5:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 6:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 7:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 8:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 9:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 10:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
          break;
        case aux === 11:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          break;
        case aux === 12:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4');
          break;
        case aux === 13:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          break;
        case aux === 14:
          num = num.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
          break;
        case aux > 14:
          num = (num.substring(0, num.length - 1)).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
          break;
      }
    }
    return num;
  }
}
