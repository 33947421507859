import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
    NavigationExtras
} from '@angular/router';
import { CredenciaisService } from './credenciais.service';

@Injectable()
export class LoginGuardService implements CanActivate, CanActivateChild {

    constructor(private router: Router, private credenciaisService: CredenciaisService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string) {
        if (this.credenciaisService.estaLogado(url)) { return true; }
        this.router.navigate(['/login']);
        return false;
    }
}
