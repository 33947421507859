import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ShellService } from '../../../core/shell.service';
import { ForgotPasswordService } from './forgot-password.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public formGroup: FormGroup;
  public enviado: boolean = false;
  public mensagem: string;
  public color: string = "green";

  constructor(
    private formBuilder: FormBuilder,
    private forgotPasswordService: ForgotPasswordService) {
    }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(null, Validators.required)
    });
  }

  save(formModel: any, isValid: boolean) {
    this.color = "green"
    this.enviado = true;
    this.mensagem = "Enviando....."
   // console.log("forgotpassword",formModel.email)
    this.forgotPasswordService.forgotpassword(formModel.email).subscribe(res => {
      if(res) {        
        this.mensagem = "Email enviado com sucesso!"
        this.color = "green"
      }
    },
      (err) => {
        let mssg = err.error.message
        if(mssg) {
          this.mensagem = mssg
          this.color = "red"
        }
        else {          
          this.mensagem = "Ocorreu um erro no envio do email!"
          this.color = "red"
        }
      });
  }

  // solicitarToken(username: string, password: string) {    
  //   this.loginService.solicitarToken(username, password).subscribe(res => {
  //       // this.credenciaisService.User = JSON.stringify(res.user),
  //       // this.credenciaisService.Role = res.role,
  //       // this.credenciaisService.Token = res.token;
  //       // this.credenciaisService.Menu = JSON.stringify(res.menu);
  //       // this.credenciaisService.Funcionalidades = JSON.stringify(res.funcionalidades);
  //       // this.navegacaoService.redirecionar();
  //       this.enviado = true;
  //   },
  //     err => {
  //       this.enviado = false;
  //     });
  // }
}
