'use strict';

// String para apresentação
export const nomeAPP = 'Sistema de Gestão do Projeto São José';
export const sigla = 'Integra';
export const versao = '2023.03.03.V01';

// Strings de configuração
//export const ref  = '/agropolos/';
export const ref  = '';
export const iconeApp = 'assets/img/gears.png';
export const guardiaoP = '';
export const tokenHeader  = 'Authorization'; // Java: 'x-access-token'
export const prefixTokenHeader  = 'Bearer '; // Java: ''
export const rowsNumberPerPage = 15;//numero de itens por página
export const ano = (new Date()).getFullYear();
