import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { CredenciaisService } from "../../../core/credenciais.service";

export interface MenuPai {
  id: number;
  descricao: string;
  subMenu: string;
  icone: string;
  menusFilhos: MenuFilho[];
  menusFilhosSub: MenuFilhoSub[];
}

export interface MenuFilho {
  id: number;
  descricao: string;
  link: string;
  icone: string;
}

export interface MenuFilhoSub {
  id: number;
  descricao: string;
  link: string;
  icone: string;
  submenuid: number;
}

@Component({
  selector: "app-menu",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menus: MenuPai[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private credenciaisService: CredenciaisService
  ) {
    this.carregaMenus();
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  carregaMenus() {
    var menuString = this.credenciaisService.Menu;
    var menus = JSON.parse(menuString);
    if (menus) {

      menus.forEach((mp) => {
        //console.log("carregaMenus",mp) //lista menus
        let mnp = {} as MenuPai;
        mnp.id = mp.id;
        mnp.icone = mp.icone;//"view_list";
        mnp.descricao = mp.descricao;
        mnp.menusFilhos = [];
        mnp.menusFilhosSub = [];

        mp.menusFilhos.forEach((mf) => {
          let mnf = {} as MenuFilho;
          mnf.id = mf.id;
          mnf.descricao = mf.descricao;
          mnf.link = mf.link;
          mnf.icone = mf.icone;
          mnp.menusFilhos.push(mnf);
        });
        mp.menusFilhosSub.forEach((mf) => {
          let mnf = {} as MenuFilhoSub;
          mnf.id = mf.id;
          mnf.descricao = mf.descricao;
          mnf.link = mf.link;
          mnf.icone = mf.icone;
          mnf.submenuid = mf.submenuid
          mnp.menusFilhosSub.push(mnf);
        });
        this.menus.push(mnp);
      });
    }
  }

  public isLinkActive(url) {
    const charPos = this.router.url.indexOf(url);
    return charPos !== -1;
  }
}
