import { Component, OnInit, Input, ViewChild, Optional, OnDestroy, Output, EventEmitter, TemplateRef, ContentChild } from '@angular/core';
import { MatSortHeader, MatSort, Sort } from '@angular/material/sort';
import { MatColumnDef, MatTable, MatHeaderCellDef } from '@angular/material/table';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as _ from 'lodash';

@Component({
  selector: 'agropolos-grid-column',
  templateUrl: './agropolos-grid-column.component.html',
  styleUrls: ['./agropolos-grid-column.component.scss']
})
export class AgropolosGridColumnComponent implements OnInit, OnDestroy {

  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;
  @ViewChild(MatSortHeader, { static: false }) sortHeader: MatSortHeader;
  @ViewChild(MatHeaderCellDef, { static: true }) headerCellDef: MatHeaderCellDef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ContentChild(TemplateRef,  {static: true}) templateRef: TemplateRef<any>;

  @Output() notifySortData: EventEmitter<Sort> = new EventEmitter();

  @Input()
  get name(): string { return this._name; }
  set name(name: string) {
    this._name = name;
    this.columnDef.name = name;
  }
  _name: string;

  @Input()
  get sortable(): boolean { return this._sortable; }
  set sortable(sortable: boolean) {
    this._sortable = coerceBooleanProperty(sortable);
  }
  _sortable: boolean;

  @Input()
  get actions(): boolean { return this._actions; }
  set actions(actions: boolean) {
    this._actions = coerceBooleanProperty(actions);
  }
  _actions: boolean;

  @Input() label: string;

  @Input() align: 'before' | 'after' = 'before';

  constructor(@Optional() public table: MatTable<any>) { }

  ngOnInit() {
    this.columnDef.name = this.name;
  }

  ngOnDestroy() {
  }

  getData(data: any): any {
    const nameSplitted = this.name.split('.');
    const nameCamelCase = nameSplitted.map(x => _.camelCase(x));
    const dataValue = nameCamelCase.reduce((a, c) => (a[c] && (a = a[c])) || (data[c] && (a = data[c])), {});
    return dataValue;

    // if (this.name === 'TipoTag.Nome') {
    //   const test2 = nameCamelCase.reduce((a, c) => {
    //     if (a[c]) {
    //       return a = a[c];
    //     } else if (data[c]) {
    //       return a = data[c];
    //     }
    //   }, {});
    // }
    // return this.getParameterCaseInsensitive(data, this.name);
  }

  private getParameterCaseInsensitive(object, key) {
    return object[Object.keys(object)
      .find(k => k.toLowerCase() === key.toLowerCase())
    ];
  }

  sortData(sort: Sort) {
    this.notifySortData.emit(sort);
  }
}
