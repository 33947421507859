import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Paginacao } from '../../../models/paginacao';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'agropolos-grid',
  templateUrl: './agropolos-grid.component.html',
  styleUrls: ['./agropolos-grid.component.scss']
})
export class AgropolosGridComponent implements OnInit {

  @Input() pagination: Paginacao<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor() { }

  ngOnInit() {
  }
}
