import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { CredenciaisService } from '../../../core/credenciais.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthenticatedUser } from '../../../models/authenticated-user';

@Injectable()
export class AberturaEditalService {
  private currentUserSubject: BehaviorSubject<AuthenticatedUser>;

  private apiUrl = '';
   private body: Object = {};
   private headers: HttpHeaders;

  constructor(private http: HttpClient,
    public credenciaisService: CredenciaisService,) {
    this.apiUrl = environment.accessControlUrl;
  }

  login(cpf: string, password: string) {
    return this.http.post<any>(`${environment.accessControlUrl}/account/login`, { cpf, password })
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.credenciaisService.User = JSON.stringify(data.user),
        this.credenciaisService.Role = JSON.stringify(data.user.role),
        this.credenciaisService.Token = data.token;
        this.credenciaisService.Menu = JSON.stringify(data.menu);
        this.credenciaisService.Funcionalidades = JSON.stringify(data.funcionalidades);
        return data;
      }));
  }

  solicitarToken(username: string, password: string) {
    return this.http.post<any>(`${this.apiUrl}/account/login`, { username, password });
  }  

  listarFuncionalidades() {
    return this.http.get<any>(`${this.apiUrl}/funcionalidade/listarfuncionalidades`);
  }

  listaPerfilFuncionalidade(funcionalidadeId: number) {
    return this.http.get<any>(`${this.apiUrl}/perfil/listarPerfilPorfuncionalidade?funcionalidadeId=` + funcionalidadeId);
  }


}
