import { FormGroup, FormControl, AbstractControl } from "@angular/forms";

export const loggar = window.location.hostname.indexOf("localhost") >= 0;


export function setDatepicker(data: string) {
  data = data.replace(/(\d{2})[-,-](\d{2})[-,-](\d{+})/, "$2/$1/$3").substring(0, 10);
  if (data.substring(6, 10) === "0000" || data ==="" ) {
    return "";
  } else {
    return new Date(toDate(data));
  }
}

function toDate(dateStr) {
  var parts = dateStr.split("/");
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

export function validarAnobrowser(data: string) {
  if (data.substring(6, 10) === "0000" || data ==="" ) { return false; }
  else { return true; }
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });
}


export function CpfValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value !== undefined && control.value !== null) {
    let cpfValue = control.value.replace(/[^0-9]+/g, '');
    if (!validarCpf(cpfValue)) {
      return { 'CpfValidator': true };
    }
  }
  return null;
}


export function validarCpf(cpf: string): boolean {
  if (cpf == null) {
    return false;
  }
  if (cpf.length != 11) {
    return false;
  }
  if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
    return false;
  }
  let numero: number = 0;
  let caracter: string = '';
  let numeros: string = '0123456789';
  let j: number = 10;
  let somatorio: number = 0;
  let resto: number = 0;
  let digito1: number = 0;
  let digito2: number = 0;
  let cpfAux: string = '';
  cpfAux = cpf.substring(0, 9);
  for (let i: number = 0; i < 9; i++) {
    caracter = cpfAux.charAt(i);
    if (numeros.search(caracter) == -1) {
      return false;
    }
    numero = Number(caracter);
    somatorio = somatorio + (numero * j);
    j--;
  }
  resto = somatorio % 11;
  digito1 = 11 - resto;
  if (digito1 > 9) {
    digito1 = 0;
  }
  j = 11;
  somatorio = 0;
  cpfAux = cpfAux + digito1;
  for (let i: number = 0; i < 10; i++) {
    caracter = cpfAux.charAt(i);
    numero = Number(caracter);
    somatorio = somatorio + (numero * j);
    j--;
  }
  resto = somatorio % 11;
  digito2 = 11 - resto;
  if (digito2 > 9) {
    digito2 = 0;
  }
  cpfAux = cpfAux + digito2;
  if (cpf != cpfAux) {
    return false;
  }
  else {
    return true;
  }
}

