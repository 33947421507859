import { CredenciaisService } from '../../../../core/credenciais.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, Pipe } from '@angular/core';
import { AgropolosDialogService } from '../../../components/agropolos-dialog/agropolos-dialog.service';
import { ChangePasswordComponent} from '../../../components/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HttpBaseService } from '../../../../core/http-base.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportarErroComponent } from '../../../components/reportar-erro/reportar-erro.component';

@Component({
  selector: 'agropolos-toolbar-user-profile',
  templateUrl: './agropolos-mat-toolbar-user-profile.component.html',
  styleUrls: ['./agropolos-mat-toolbar-user-profile.component.scss']
})
export class AgropolosMatToolbarUserProfileComponent implements OnInit {

  @Input() perfilUsuario: string;
  @Input() nomeUsuario: string;
  @Input() emailUsuario: string;
  @Input() cpfUsuario: string;
  @Input() secretaria: string;

  @Input() homeTooltip: string;
  @Input() reportBugLocation: string;
  @Input() homeLocation: string;

  @Output() logoff: EventEmitter<any> = new EventEmitter<any>();
  @Output() reportBug: EventEmitter<any> = new EventEmitter<any>();
  @Output() homeClick: EventEmitter<any> = new EventEmitter<any>();

  public funcionalidade: string;
  public showMenu:boolean=false; 
  public nomeCompletoUsuario:string;
  public usuarioLogado:any;

  public showMidia: boolean = false;
  public imagePath: any;
  public imagePathmidia: any;

  constructor(
    private dialog: AgropolosDialogService,
    private credenciaisService: CredenciaisService,
    public matdialog: MatDialog,
    private view: ViewContainerRef,
    private route: ActivatedRoute,
    private http: HttpBaseService,
    private _sanitizer: DomSanitizer
    ) {
    this.dialog.viewContainerRef = this.view;
  }

  ngOnInit() {
    this.funcionalidade = this.route.snapshot['_routerState'].url.replace('/', '').split('/', 1)[0];
    this.usuarioLogado = JSON.parse(localStorage.getItem("currentUser"));
    //console.log("AgropolosMatToolbarUserProfile---usuarioLogado", this.usuarioLogado)
    this.buscarPessoaFisicaLogado();
    this.buscarFuncionarioLogado();
    if(this.funcionalidade == 'editalmulheresruraiscadastro'){
      this.showMenu = false;
    } else if(this.funcionalidade == 'licitaben'){
      this.showMenu = false;
    }
    else if(this.funcionalidade == 'pesquisasatisfacaofeira'){
      this.showMenu = false;
    }
    else if(this.funcionalidade == 'fazendocontas'){
      this.showMenu = false;
    }
    else
    {this.showMenu = true;}
  }

  preencheInfos() {

  }

  onLogoff(event: any) {
    const config = {
      title: 'Sair do sistema.',
      content: 'Você deseja mesmo sair do sistema?',
      actions: [
        { name: 'NÃO', closeResult: false, color: 'accent' },
        { name: 'SIM', closeResult: true, color: 'primary' }
      ],
      isAlert: true,
      alertType: 'confirm'
    };

    this.dialog.open(config).afterClosed().subscribe(
      result => {
        this.logoff.emit(event);
        if (result) {
          //this.localStorageService.setObject('gridSettingsModel', null);
          this.credenciaisService.logout();

          if(JSON.parse(localStorage.getItem("currentUser")) != null)
          {localStorage.setObject("currentUser", null);}
        }
      }
    );
  }

  chancePassword(): void {
    const _dialog = this.matdialog.open(
      ChangePasswordComponent,
      {
        width: '600px',
        height: '420px',
        data: { emailUsuario : this.emailUsuario}
      }
    );
    _dialog.afterClosed().subscribe(() => {
      
    });
  }

  onReportBug(event: any) {
    const _dialog = this.matdialog.open(
      ReportarErroComponent,
      {
        width: '600px',
        height: '420px',
        data: { usuarioLogado : this.usuarioLogado}
      }
    );
    _dialog.afterClosed().subscribe(() => {
      
    });
    // window.open(this.reportBugLocation, '_blank');
    // this.reportBug.emit(event);
  }

  onHomeClick(event: any) {
    this.homeClick.emit(event);
    window.open(this.homeLocation);
  }

  nomeAbreviado() {
    const nameArray: Array<string> = this.nomeUsuario.split(' ');
    const lastIndex: number = nameArray.length - 1;

    return nameArray[0] + ' ' + nameArray[lastIndex];
  }

  nome() {
    return this.nomeCompletoUsuario;//this.nomeUsuario
  }

  private buscarPessoaFisicaLogado() {    
    if(this.usuarioLogado != null)
    {      
      //this.http.get(`/PessoaFisica/BuscaPessoaFisicaLogado/${usuarioLogado.cpf}`, false).subscribe((data: any) => {
        this.http.get(`/PessoaFisica/BuscaPessoaFisicaLogadoHash/${this.usuarioLogado.hash}`, false).subscribe((data: any) => {
        if (data != null) {
          var pessoaFisicaLogado = Object(data)
          this.nomeCompletoUsuario = pessoaFisicaLogado.dscNomeCompleto;
          //console.log("buscarPessoaFisicaLogado", pessoaFisicaLogado)
        }
      });
    }
  }

  private buscarFuncionarioLogado() {    
    if(this.usuarioLogado != null)
    {      
        this.http.get(`/Funcionario/BuscaFuncionarioLogadoHash/${this.usuarioLogado.hash}`, false).subscribe((data: any) => {
        if (data != null) {
          //console.log("buscarFuncionarioLogado", data)
          var funcionarioLogado = Object(data)
          this.showMidia = funcionarioLogado.funcionario.fotoTecnico != null;
          this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(funcionarioLogado.funcionario.fotoTecnico);
          this.imagePathmidia = funcionarioLogado.funcionario.fotoTecnico;
        }
      });
    }
  }


}
