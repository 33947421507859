import { Injectable } from '@angular/core';
import { RestfulService } from '../../../core/restful.service';
import { HttpBaseService } from '../../../core/http-base.service';
import { ListaPresencaEvento } from '../../../models/integra/lista-presenca-evento';

@Injectable()
export class ListaPresencaEventoService extends RestfulService<ListaPresencaEvento>{

  constructor(private _http: HttpBaseService) {
    super(_http, 'listapresencaevento');
  }
}
