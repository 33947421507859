import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'cep' })
export class CepPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let str = value + '';

    str = str.replace(/\D/g, '');

    if (str.length === 10 ) {
    str = str.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
    }else {
    str = str.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
    }
    return str;

  }
}
