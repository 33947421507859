import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
//import { VideoManualModalModel } from '../../../models/integra/VideoManualModalmodel';

@Injectable({
  providedIn: 'root'
})
export class VideoManualModalService {

  private apiUrl = '';

  constructor(private http: HttpClient) { 
    this.apiUrl = environment.resetPassUrl;
  }

  // forgotpassword(email: string) {
  //   return this.http.get<any>(`${this.apiUrl}/manage/forgotpassword?email=` + email);
  // }

  // VideoManualModal(model: VideoManualModalModel) {
  //   return this.http.post<any>(`${this.apiUrl}/manage/VideoManualModal`, model);
  // }

}
