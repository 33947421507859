import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';

import { AgropolosMatSidenavService } from '../agropolos-mat-sidenav/agropolos-mat-sidenav.service';

@Component({
  selector: 'agropolos-sidenav-selection-list',
  templateUrl: './agropolos-mat-sidenav-selection-list.component.html',
  styleUrls: ['./agropolos-mat-sidenav-selection-list.component.scss']
})
export class AgropolosMatSidenavSelectionListComponent implements OnInit {

  @Input() lista: Array<any>;
  @Input() valorItem: string;
  @Input() textoItem: string;
  @Input() checkItem: string;

  @Input() disableRipple = false;
  @Input() disabled = false;
  @Output() selectionChange: EventEmitter<MatSelectionListChange>;

  @Input() checkboxPosition = 'after';
  @Input() selected = false;
  @Input() value: any;

  constructor(private sepSidenavService: AgropolosMatSidenavService) { }

  ngOnInit() {
  }

  optCheck(optCheck) {
    return this.sepSidenavService.searchInside(optCheck, this.checkItem);
  }

  optValue(optValue) {
    return this.sepSidenavService.searchInside(optValue, this.valorItem);
  }

  optText(optText) {
    return this.sepSidenavService.searchInside(optText, this.textoItem);
  }

  onSelectionChange(event: any) {
    this.selectionChange.emit(event);
  }

}
