import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'cpf' })
export class CPFPipe implements PipeTransform {
    // tslint:disable-next-line:typedef
    transform(value) {
        if (value !== null && value !== undefined && value.length > 0) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3-\$4');
        } else {
            return null;
        }
        return null;
    }

}
