import { AgropolosMatProgressbarComponent } from './agropolos-mat-progressbar.component';
import { Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

export class ProgressOptions {
  mode = 'indeterminate';
  blockUI = true;
  callBeforeShown = () => {};
  callWhileShowing = () => {};
  callAfterShown = () => {};
}

@Injectable()
export class AgropolosMatProgressbarService {

  emitChangedColor = new EventEmitter<string>();
  emitChangedMode = new EventEmitter<string>();
  emitProgress = new EventEmitter<number>();
  emitIncrement = new EventEmitter<number>();
  emitDecrement = new EventEmitter<number>();
  emitBufferProgress = new EventEmitter<number>();
  emitDisplay = new EventEmitter<string>();
  emitBlockUI = new EventEmitter<boolean>();

  private _value = 0;
  private _speed: number;
  private _interval;

  constructor() { }

  changeColor(color: string) {
    this.emitChangedColor.emit(color);
  }

  changeMode(mode: string) {
    this.emitChangedMode.emit(mode);
  }

  increment(value: number) {
    this._value += value;
    this.emitIncrement.emit(value);
  }

  decrement(value: number) {
    this._value -= value;
    this.emitIncrement.emit(value);
  }

  progress(value: number) {
    this._value = value;
    this.emitProgress.emit(value);
  }

  bufferProgress(value: number) {
    this.emitBufferProgress.emit(value);
  }

  blockUI(block: boolean) {
    this.emitBlockUI.emit(block);
  }

  public show(opt?: ProgressOptions) {
    if (opt === undefined) {
      opt = new ProgressOptions();
    }

    if (opt.mode !== undefined) {
      this.changeMode(opt.mode);
    }
    if (opt.blockUI !== undefined) {
      this.blockUI(opt.blockUI);
    }

    Promise.resolve(opt.callBeforeShown != null)
      .then(res => {
        if (res) {
          opt.callBeforeShown();
        }
        return (opt.callWhileShowing != null);
      }).then(res => {

        this.emitDisplay.emit('inline-block');
        if (res) {
          opt.callWhileShowing();
        }
        return (opt.callAfterShown != null);
      }).then(res => {

        return (res ? this.emitDisplay.subscribe(() => { opt.callAfterShown(); }) : false);
      }).catch((err) => {
        //console.log('[show function]:', err.message);
      });
  }

  hide() {
    clearInterval(this._interval);
    this.emitDisplay.emit('none');
  }

  value(): number {
    return this._value;
  }

  test(speed?: number, delay?: number) {
    this._speed = (speed !== undefined ? speed : 1);

    const timerConfig = () => {
      if (this.value() < 100) {
        this.increment(this._speed);
      } else {
        this.hide();
        this.progress(0);
      }
    };

    this._interval = setInterval(() => timerConfig(), (delay !== undefined ? delay : 50));
  }
}
