import * as strings from '../../../../assets/strings';
import { AberturaEditalService } from './abertura-edital.service';
import { NavegacaoService } from '../../../core/navegacao.service';
import { CredenciaisService } from '../../../core/credenciais.service';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorageService } from '../../../core/local-storage.service';
import { MatDialog } from "@angular/material/dialog";
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { catchError, first, tap } from 'rxjs/operators';
import { HttpBaseService } from '../../../core/http-base.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { startWith, map, take } from "rxjs/operators";

@Component({
  templateUrl: './abertura-edital.component.html',
  styleUrls: ['./abertura-edital.component.scss']
})
export class AberturaEditalComponent implements OnInit {

  public hoje: any = new Date(moment(Date.now()).format('YYYY-MM-DD'));  
  public abertura: any;//  = new Date(moment('2024-04-18').format('YYYY-MM-DD'));
  public encerramento: any;//  = new Date(moment('2024-05-07').format('YYYY-MM-DD'));

  mensagem: string;
  exibirLoading: boolean;
  error: boolean;
  sigla: string;
  appIcon: string;
  theme: string;
  hide: boolean = true;
  public funcionarioLogado: string;
  public videoPath: any;  
  public urlftp: string;
  public urlEdital: string;
  public urlManualPreCadastro: string;
  public urlManualInscricao: string;
  public showPreCadastro:boolean=false;
  public showAbertura:boolean=false;
  public showEncerramento:boolean=false;
  public retornoUrl:any;
  public ipAddress:any;

  constructor(private loginService: AberturaEditalService,
    public credenciaisService: CredenciaisService,
    private localStorageService: LocalStorageService,
    private navegacaoService: NavegacaoService,
    private http: HttpBaseService,
    public dialog: MatDialog,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private httpClient: HttpClient,
  ) {
    //  this.theme = (this.localStorageService.getObject('theme-app') === 0 || this.localStorageService.getObject('theme-app') !== undefined  
    //  ? 'agropolos-material-theme' : 'agropolos-material-dark-theme');
  }

  private retornoIp()
  {
    // this.httpClient.get('https://jsonip.com')
    //   .subscribe( data => {
    //     console.log('jsonip', data);
    //     this.ipAddress = data;
    //   })

    this.httpClient.get('https://geolocation-db.com/json/')
    .subscribe( data => {
      console.log('geolocation', data);
      this.ipAddress = data;
      console.log('geolocation---IPv4', Object(data).IPv4);
      // console.log('geolocation---city', Object(data).city);
      // console.log('geolocation---country_name', Object(data).country_name);
      // console.log('geolocation---latitude', Object(data).latitude);
      // console.log('geolocation---longitude', Object(data).longitude);
      // console.log('geolocation---state', Object(data).state);        
    })
  }

  ngOnInit() {  
    //this.retornoIp();    

   // localStorage.setObject("currentUser", null);
    //this.localStorageService.setObject('gridSettingsModel', null);

    this.http.get('/dominio/BuscarInfoEditalMulher?tipo=editalmulherrural', false).subscribe((info: any) => {
      //console.log("ngOnInit--aberturaedital", info)
      this.abertura = new Date(moment(info.nome).format('YYYY-MM-DD')); 
      this.encerramento = new Date(moment(info.descricao).format('YYYY-MM-DD')); 
      console.log("ngOnInit--this.hoje.getTime()", this.hoje.getTime())
      //this.showPreCadastro = this.hoje.getTime() >= this.abertura.getTime() && this.hoje.getTime() <= this.encerramento.getTime();
      this.showAbertura = this.hoje.getTime() >= this.abertura.getTime() && this.hoje.getTime() <= this.encerramento.getTime();
      this.showEncerramento = this.hoje.getTime() > this.encerramento.getTime();

      //console.log("ngOnInit--showAbertura", this.showAbertura)
      //console.log("ngOnInit--showEncerramento", this.showEncerramento)

      this.sigla = strings.sigla.toUpperCase();
      this.appIcon = strings.iconeApp;
      this.urlftp = environment.urlDownloadFile;
      this.urlEdital = environment.urlDownloadFile + "Edital_01_2024_-_Mulheres_Rurais.pdf";
      this.urlManualPreCadastro = environment.urlDownloadFile + "Edital_01_2024_-_Mulheres_Rurais_Manual_PreCadastro.pdf";
      this.urlManualInscricao = environment.urlDownloadFile + "Edital_01_2024_-_Mulheres_Rurais_Manual_Inscricao.pdf";

    });
    
    // this.http.get('/AtendimentoAplicativo/' + 4, false).subscribe((info: any) => {
    //   this.videoPath = this._sanitizer.bypassSecurityTrustResourceUrl(info.midia);
    // });

  }

  editalMulher() {
    this.router.navigate([`editalmulheresruraiscadastro`]);
  }

  // solicitarToken(cpf: string, password: string) {
  //   this.mensagem = 'Autenticando...';
  //   this.exibirLoading = true;
  //   this.loginService.login(cpf, password).pipe(first()).subscribe(data => {

  //     var usuarioLogado = JSON.parse(localStorage.getItem("currentUser"));
  //     // this.http.get(`/Funcionario/BuscaFuncionarioLogado/${usuarioLogado.cpf}`, false).subscribe((data: any) => {
  //     //   this.funcionarioLogado = data.funcionario.pessoaFisica.dscNomeCompleto;
  //     // }); 
  //     //console.log("usuarioLogado",usuarioLogado)

  //     if (usuarioLogado.role == "ProjetoJovem") {
  //       this.http.get('/miproponentejovem/localizaJovem?cpf=' + usuarioLogado.cpf, false).subscribe((info: any) => {
  //         this.router.navigate([`miproponentejovem/cadastro`, info.id]);
  //       });
  //     } else if (usuarioLogado.role == "ProjetoMulher") {
  //       this.http.get('/mimulherrural/localizaMulher?cpf=' + usuarioLogado.cpf, false).subscribe((info: any) => {
  //         this.router.navigate([`mimulherrural/cadastro`, info.id]);
  //       });
  //     }
  //     else { this.navegacaoService.redirecionar(); }
  //   },
  //     error => {
  //       //console.log(error)
  //       this.exibirLoading = true;
  //       if (error.status == 401)
  //         this.mensagem = error.error.message;
  //       else
  //         this.mensagem = error.error.message;
  //     });
  // }

  // resetarSenha() {
  //   this.dialog.open(ForgotPasswordComponent, {
  //     width: '600px',
  //   });
  //   // const _dialog = this.dialog.open(DominioCadastroComponent, {
  //   //   maxHeight: "80%",
  //   //   maxWidth: "90%",
  //   //   data: {
  //   //     id: 0,
  //   //     tipo: "TipoItemCusteio",
  //   //     tituloTipo: "Tipo Item Custeio"
  //   //   },
  //   //   disableClose: false
  //   // });
  //   // _dialog.afterClosed().subscribe(
  //   //   r => {
  //   //     this.listagemTipoCusteio();
  //   //   },
  //   //   erro => console.log(erro)
  //   // );
  // }




}

