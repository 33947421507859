import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[maskCpf]' })
export class MaskCpfDirective {

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPressEnter: EventEmitter<any> = new EventEmitter();

  arrayNumber: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  arrayFunction: any[] = [, 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

  public el: HTMLElement;
  constructor(public element: ElementRef) {
    this.el = this.element.nativeElement;
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const htlmElement = (<HTMLInputElement>this.el);
    if (event.key === 'Enter') {
      this.onPressEnter.emit();
    } else if (this.arrayFunction.indexOf(event.key) < 0) {
      htlmElement.value = this.convertToCpf(htlmElement.value);
    }
  }

  private convertToCpf(num) {
    if (num) {
      num = num.toString();
      num = num.replace(/\D/g, '');
      const aux: number = num.length;

      switch (true) {
        case aux === 4:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 5:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 6:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 7:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 8:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 9:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 10:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
          break;
        case aux === 11:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          break;
        case aux > 11:
          num = (num.substring(0, num.length - 1)).replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          break;
      }
    }
    return num;
  }

  public validarCpf(cpf: string): boolean {
    if (cpf == null) {
        return false;
    }
    if (cpf.length !== 11) {
        return false;
    }
    if ((cpf === '00000000000') || (cpf === '11111111111')
     || (cpf === '22222222222') || (cpf === '33333333333')
     || (cpf === '44444444444') || (cpf === '55555555555')
     || (cpf === '66666666666') || (cpf === '77777777777')
     || (cpf === '88888888888') || (cpf === '99999999999')) {
       return false;
     }
    let numero = 0;
    let caracter = '';
    const numeros = '0123456789';
    let j = 10;
    let somatorio = 0;
    let resto = 0;
    let digito1 = 0;
    let digito2 = 0;
    let cpfAux = '';
    cpfAux = cpf.substring(0, 9);

    for (let i = 0; i < 9; i++) {
        caracter = cpfAux.charAt(i);
        if (numeros.search(caracter) === -1) {
            return false;
        }
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
        digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i = 0; i < 10; i++) {
        caracter = cpfAux.charAt(i);
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
        digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf !== cpfAux) {
        return false;
    } else {
        return true;
    }
}
}
