import { NgModule } from "@angular/core";
import { ListaPresencaEventoRoutingModule } from "./lista-presenca-evento-routing.module";
import { ListaPresencaEventoComponent } from "./lista-presenca-evento.component";
import { SharedModule } from "../../../shared/shared.module";
import { Data } from "../../../core/providers/data";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { MatDatepickerModule, MatDialogModule, MatNativeDateModule, MatTabsModule } from "@angular/material";
import { MaterialModulos } from "../../../material-modulos.module";
import { RouterModule } from "@angular/router";
import { ListaPresencaEventoService } from "./lista-presenca-evento.service";
import { ListaPresencaEmailDialogComponent } from "./assinatura-dialog/assinatura-dialog.component";

@NgModule({
  imports: [
    SharedModule,
    CurrencyMaskModule,
    MatTabsModule,
    MatDialogModule,
    MaterialModulos,
    MatDatepickerModule,
    MatNativeDateModule,
    ListaPresencaEventoRoutingModule,
  ],
  entryComponents: [
    ListaPresencaEmailDialogComponent
  ],
  exports: [
    RouterModule,
    MatDatepickerModule,
    ListaPresencaEventoComponent
  ],
  declarations: [ListaPresencaEventoComponent,
    ListaPresencaEmailDialogComponent],
  providers: [Data, ListaPresencaEventoService]
})
export class ListaPresencaEventoModule {}
