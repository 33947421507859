import { Component, OnInit } from '@angular/core';
import * as strings from '../../../../assets/strings';
import { HttpBaseService } from '../../../core/http-base.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'agropolos-footer',
  templateUrl: './agropolos-footer.component.html',
  styleUrls: ['./agropolos-footer.component.scss']
})
export class AgropolosFooterComponent implements OnInit {
  public versaoSistema: any;
  public showFooter:boolean=true;
  public funcionalidade: string;
  
  str = {
    nomeApp: strings.nomeAPP, 
    sigla: strings.sigla,
    versao: strings.versao,
    ano: strings.ano  

  };

  constructor(
    private http: HttpBaseService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
  this.funcionalidade = this.route.snapshot['_routerState'].url.replace('/', '').split('/', 1)[0]
    this.http.get("/Seplag/ExibeVersaoSistema", false).subscribe((retorno: any) => {
      ////console.log("ngOnInit----ExibeVersaoSistema",  Object(retorno)["versaoSistema"]);
      this.versaoSistema = Object(retorno)["versaoSistema"];

      if (this.funcionalidade == 'pesquisasatisfacaofeira') {
        this.showFooter = false;
      }
      else if (this.funcionalidade == 'fazendocontas') {
        this.showFooter = false;
      }
      else{
        this.showFooter = true;
      }

    })
  };

}
