import { Component, OnInit, Input, AfterViewInit, AfterContentInit, AfterContentChecked, ViewChild, ContentChild, ContentChildren, QueryList, Output, EventEmitter, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AgropolosGridColumnComponent } from '../agropolos-grid-column/agropolos-grid-column.component';
import { GridSettings } from '../../../../models/GridSettings';
// import { AgropolosGridFilterComponent } from '../agropolos-grid-filter/agropolos-grid-filter.component';
import { Data } from '../../../../core/providers/data';

@Component({
  selector: 'agropolos-grid-table',
  templateUrl: './agropolos-grid-table.component.html',
  styleUrls: ['./agropolos-grid-table.component.scss']
})
export class AgropolosGridTableComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterContentInit {

  // @Input() gridSettingsModel: GridSettings;
  public gridSettingsModel: GridSettings;
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @Output() notifySortData: EventEmitter<Sort> = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  // @ContentChild(AgropolosGridFilterComponent) filter: AgropolosGridFilterComponent;
  @ContentChildren(AgropolosGridColumnComponent) columns: QueryList<AgropolosGridColumnComponent>;

  public displayedColumns: string[] = new Array<string>();

  constructor(
    private data: Data,
    private _cdRef: ChangeDetectorRef,
  ) { }


  ngOnInit() {
    this.gridSettingsModel = this.data.gridSettings;
    this.dataSource = new MatTableDataSource<any>();
    // if (this.dataSource) {
    //   this.dataSource.sort = this.sort;
    // }
  }

  ngAfterViewInit(): void {
    this.columns.map(c => {
      c.notifySortData = this.notifySortData;
      this.table.addColumnDef(c.columnDef);
      this.displayedColumns = this.displayedColumns.concat(c.name);
    });
    this._cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
  }

  ngAfterContentInit() {
    // this.filter.gridSettingsModel = this.gridSettingsModel;
    // this.filter.ngOnChanges({
    //   gridSettingsModel: new SimpleChange(null, this.gridSettingsModel, true)
    // });
  }

  public sortChange(sort: Sort) {
    this.notifySortData.emit(sort);
  }
}
