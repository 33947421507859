import { environment } from '../../environments/environment';
import { CredenciaisService } from './credenciais.service';
import { ShellService } from './shell.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ModalLogoffService {

  constructor(private shellService: ShellService,
              private credenciaisService: CredenciaisService) { }

  expirarSessao() {

    const config = {
        title: 'Sessão expirada!',
        content: 'O tempo da sessão expirou, efetue o login novamente.',
        isAlert: true,
        alertType: 'warning'
      };

    this.shellService.dialog().open(config).afterClosed().subscribe(
      closeResult => {
        if (closeResult) {
          this.credenciaisService.logout();
          // window.location.replace(environment.guardiaoLoginUrl);
        }
      }
    );
  }

}
