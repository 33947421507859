import * as strings from '../assets/strings';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShellModule } from './shell/shell.module';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule, MatGridListModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModulos } from './material-modulos.module';

import { AgmCoreModule } from '@agm/core';

//import { MatStepperModule } from '@angular/material/stepper';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register(strings.ref + 'ngsw-worker.js', { enabled: environment.production || environment.homologacao }),
    CoreModule.forRoot(),
    ShellModule,
    AppRoutingModule,
    MatDialogModule,
    FlexLayoutModule,
    MaterialModulos,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDf1E4QhKn4pAbBrYlldPasrba_mZTwPpc'
    })
    //MatDatepickerModule,
    //MatStepperModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {  }
}
