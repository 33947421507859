import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { AgropolosDialogService } from './agropolos-dialog.service';

@Component({
  selector: 'agropolos-dialog',
  templateUrl: './agropolos-dialog.component.html',
  styleUrls: ['./agropolos-dialog.component.scss']
})
export class AgropolosDialogComponent {

  data = {
    title: '',
    content: '',
    actions: undefined,
    alertType: '',
    isAlert: false,
    materialIcon: undefined,
    alignButtons: undefined
  };

  private iconMapping = {
    'success': { icon: 'check_circle' },
    'info': { icon: 'info' },
    'warning': { icon: 'warning' },
    'error': { icon: 'error' },
    'confirm': { icon: 'live_help' }
  };

  constructor(public dialogRef: MatDialogRef<AgropolosDialogComponent>,
    private sanitizer: DomSanitizer) { }


  dContent() {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.content);
  }

  isWait(): boolean {
    return (this.data.alertType === 'wait');
  }

}
