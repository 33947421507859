import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'agropolos-file-upload',
  templateUrl: './agropolos-file-upload.component.html',
  styleUrls: ['./agropolos-file-upload.component.scss']
})
export class AgropolosFileUploadComponent implements OnInit {

  @Input() funcionalidade: string;
  @Input() tipoProduto: string;
  @Input() grupoDocumento: string;
  @Input() documento: string;

  constructor() { }

  ngOnInit() {
  }

}
