import { Component, OnInit, OnDestroy, Input, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { GridSettings } from '../../../../../../models/GridSettings';
import { AgropolosGridFilterInput } from '../agropolos-grid-filter-input';
import { Data } from '../../../../../../core/providers/data';
import { WHERE_OPERATION_FILTER } from '../../../../../../models/enums/where-operation-enum';

@Component({
  selector: 'agropolos-grid-filter-input-select',
  templateUrl: './agropolos-grid-filter-input-select.component.html',
  styleUrls: ['./agropolos-grid-filter-input-select.component.scss'],
  providers: [{ provide: AgropolosGridFilterInput, useExisting: forwardRef(() => AgropolosGridFilterInputSelectComponent) }]
})
export class AgropolosGridFilterInputSelectComponent extends AgropolosGridFilterInput implements OnInit, OnDestroy, OnChanges {
  constructor(public data: Data) {
    super();
  }

  @Input() placeholder: string;
  @Input() ngModelProperty: string;
  @Input() field: string;
  @Input() op = WHERE_OPERATION_FILTER.eq;
  @Input() selectKey: string;
  @Input() selectValue: string;
  @Input() elements: Array<any>;

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
}
