import { AberturaEditalRoutingModule } from './abertura-edital-routing.module';
import { AberturaEditalService } from './abertura-edital.service';
import { NgModule } from '@angular/core';
import { AberturaEditalComponent } from './abertura-edital.component';
import { MaterialModulos } from '../../../material-modulos.module';
import { AgropolosMaterialModule } from '../../agropolos-material.module';

@NgModule({
  imports: [AgropolosMaterialModule, AberturaEditalRoutingModule, MaterialModulos],
  declarations: [AberturaEditalComponent],
  exports: [AberturaEditalComponent],
  providers: [AberturaEditalService]
})
export class AberturaEditalModule { }
