import { Directive, ElementRef, forwardRef, HostListener,  Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[toUppercase]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UpperCaseInputDirective),
    },
  ],
})
export class UpperCaseInputDirective extends DefaultValueAccessor {
 
  // @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
  //   console.log("UpperCaseInputDirective---event", event)
  //   const input = event.target as HTMLInputElement;
  //   input.value = input.value.toUpperCase();
  //   console.log("UpperCaseInputDirective---input.value", input.value)
  // }

  @HostListener('input', ['$event']) input($event:KeyboardEvent ) {//InputEvent
    const target = $event.target as HTMLInputElement;
    const start = target.selectionStart;

    target.value = target.value.toUpperCase();
    target.setSelectionRange(start, start);

    this.onChange(target.value);
  }

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }
}
