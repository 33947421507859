import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[appPhoneMask]' })
export class MaskPhoneDirective {
  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }


  onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, '');
    //console.log('newVal.length: ', newVal.length);
    //console.log('newVal: ', newVal);
    //console.log('backspace ', backspace);

    if (backspace && newVal.length <= 15) {
      //console.log('backspace && newVal.length <= 15');
      newVal = newVal.substring(0, newVal.length);
    }
    else{
      if (newVal.length == 0) {
        newVal = '';
      } 
      // don't show braces for empty groups at the end
      else if (newVal.length <= 3) {
        newVal = newVal.replace(/^(\d{0,2})/, '($1)');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,4})/, '($1) $2');
      } else if ((newVal.length > 6) && (newVal.length <= 7)) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '($1) $2');
      } else {
        if (newVal.length <= 10)
          {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,4})(.*)/, '($1) $2-$3');
            //console.log('newVal<= 10: ', newVal);
          }
          else 
          {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,5})(.*)/, '($1) $2-$3');
            //console.log('newVal>10: ', newVal);
          }
      }
      // if (newVal.length === 0) {
      //   console.log('newVal.length === 0');
      //   newVal = '';
      // }
      // else if (newVal.length <= 2) {
      //   console.log('<= 8');
      //   newVal = newVal.replace(/^(\d{0,2})/, '($1) ');
      // }
      // else if (newVal.length >= 3 && newVal.length <= 6) {
      //   console.log('>= 9');
      //   newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '($1) $2');
      // }
      // else if (newVal.length >= 7 && newVal.length <= 14) {
      //   console.log('<= 14');
      //   newVal = newVal.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/, '($1) $2-$3');
      // }
      // else {
      //   console.log('newVal.substring(0, 14)');
      //   newVal = newVal.substring(0, 14);
      //   newVal = newVal.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/, '($1) $2-$3');
      // }
    }
    
    this.ngControl.valueAccessor.writeValue(newVal);
    //console.log("onInputChange: ", newVal)
  }

}
