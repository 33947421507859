import { AgropolosAlertService } from "../shell/components/agropolos-alert/agropolos-alert.service";
import { AgropolosMatSidenavContentService } from "../shell/layout/agropolos-sidenav/agropolos-mat-sidenav-content/agropolos-mat-sidenav-content.service";
import { AgropolosMatProgressbarService } from "../shell/components/agropolos-progressbar/agropolos-mat-progressbar.service";
import { AgropolosDialogService } from "../shell/components/agropolos-dialog/agropolos-dialog.service";
import { AgropolosMatContainerService } from "../shell/components/agropolos-container/agropolos-mat-container.service";
import { Injectable } from "@angular/core";

@Injectable()
export class ShellService {
  loggar: boolean;

  constructor(
    private AgropolosContainerService: AgropolosMatContainerService,
    private _AgropolosDialogService: AgropolosDialogService,
    private AgropolosProgressbarService: AgropolosMatProgressbarService,
    private _AgropolosAlertService: AgropolosAlertService,
    private AgropolosContentService: AgropolosMatSidenavContentService
  ) {
    this.loggar = window.location.hostname.indexOf("localhost") >= 0;
  }

  alert() {
    return this._AgropolosAlertService;
  }

  dialog() {
    return this._AgropolosDialogService;
  }

  progressbar() {
    return this.AgropolosProgressbarService;
  }

  container() {
    return this.AgropolosContainerService;
  }

  content() {
    return this.AgropolosContentService;
  }
}
