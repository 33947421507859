import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '../../../shared/interceptors/loader.service';



@Component({
  selector: 'agropolos-progressbar',
  templateUrl: './agropolos-mat-progressbar.component.html',
  styleUrls: ['./agropolos-mat-progressbar.component.scss']
})
export class AgropolosMatProgressbarComponent implements OnInit, AfterContentChecked {
  loading: boolean=false;

  constructor(private loaderService: LoaderService,private changeDetector: ChangeDetectorRef,) {
    this.loaderService.isLoading.subscribe((v) => {
      //console.log(v + "--loading");
      this.loading = v;
    });
  }

  ngOnInit() {}
    
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
   

}
