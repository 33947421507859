import { LocalStorageService } from '../../../core/local-storage.service';
import { Route, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as strings from '../../../../assets/strings';

export interface AgropolosHttpStatus {
  system: string;
  code: number;
  title: string;
  message: string;
}

@Component({
  selector: 'agropolos-agropolos-http-status',
  templateUrl: './agropolos-http-status.component.html',
  styleUrls: ['./agropolos-http-status.component.scss']
})
export class AgropolosHttpStatusComponent implements OnInit {

  httpStatus: AgropolosHttpStatus;
  theme: string;
  reportBugLocation = 'http://webmail.agropolos.ce.gov.br/';
  homeLocation = '/home';

  constructor(private route: ActivatedRoute,
    private location: Location,
    private localStorageService: LocalStorageService) {

    this.httpStatus = <AgropolosHttpStatus>route.snapshot.data;
    this.theme = (this.localStorageService.getObject('theme-app') === 0 ? 'agropolos-material-theme' : 'agropolos-material-dark-theme');
  }

  ngOnInit() { }

  goBack() {
    this.location.back();
  }

  onReportBug(event: any) {
    window.open(this.reportBugLocation, '_blank');
  }

  onHomeClick(event: any) {
    window.open(this.homeLocation, '_self');
  }

}
