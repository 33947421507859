import { NgModule } from '@angular/core';

import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';
import { ForgotPasswordComponent } from './forgot-password.component';
import { AgropolosMaterialModule } from '../../agropolos-material.module';
import { MaterialModulos } from '../../../material-modulos.module';
import { ForgotPasswordService } from './forgot-password.service';


@NgModule({
  imports: [AgropolosMaterialModule, ForgotPasswordRoutingModule, MaterialModulos],
  declarations: [ForgotPasswordComponent],
  exports: [ForgotPasswordComponent],
  entryComponents: [ForgotPasswordComponent],
  providers: [ForgotPasswordService]
})
export class ForgotPasswordModule { }
