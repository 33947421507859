import { Component, OnInit, Input, Output } from '@angular/core';

import { AgropolosMatToolbarService } from './agropolos-mat-toolbar.service';
import { AgropolosMatContainerService } from '../../../components/agropolos-container/agropolos-mat-container.service';
import { AgropolosMatSidenavContainerService } from '../../agropolos-sidenav/agropolos-mat-sidenav-container/agropolos-mat-sidenav-container.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpBaseService } from '../../../../core/http-base.service';
import { CredenciaisService } from '../../../../core/credenciais.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { AgropolosDialogComponent } from '../../../components/agropolos-dialog/agropolos-dialog.component';
import { VideoManualModalComponent } from '../../../components/video-manual-modal/video-manual-modal.component';
import { flatMap } from 'lodash';

export interface Anexo {
  id: number;
  link: any;
  nome: any;
  descricao: string;
  extensao: string;
  tamanho: number;
}

@Component({
  selector: 'agropolos-toolbar',
  templateUrl: './agropolos-mat-toolbar.component.html',
  styleUrls: ['./agropolos-mat-toolbar.component.scss']
})
export class AgropolosMatToolbarComponent implements OnInit {

  @Input() color = 'primary';
  @Input() appTitle;

  public funcionarioLogado: string;
  public funcionalidade: string;
  public ELEMENT_DATA: (Anexo)[] = [];
  public lstManuais: any[] = [];
  public showVideo: boolean = false;
  public showMenu: boolean = false;
  public showManual: boolean = false;
  public showDashboard: boolean = false;

  public environment = {
    icon: undefined,
    name: undefined,
    production: undefined,
    tooltip: undefined
  };

  constructor(private router: Router,
    private sepMatTbService: AgropolosMatToolbarService,
    private sepContainerService: AgropolosMatContainerService,
    private sepMatSidenavContainerService: AgropolosMatSidenavContainerService,
    private http: HttpBaseService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    public matdialog: MatDialog,
    //private credenciaisService: CredenciaisService
  ) { }

  ngOnInit() {

    var usuarioLogado = JSON.parse(localStorage.getItem("currentUser"));
    // this.http.get(`/Funcionario/BuscaFuncionarioLogado/${usuarioLogado.cpf}`, false).subscribe((data: any) => {
    //   this.funcionarioLogado = data.funcionario.pessoaFisica.dscNomeCompleto;
    // }); 

    //var funcionalidadesstring = this.credenciaisService.Funcionalidades

    this.funcionalidade = this.route.snapshot['_routerState'].url.replace('/', '').split('/', 1)[0]
    //console.log("---toolbar---funcionalidade", this.funcionalidade);
    //console.log("usuarioLogado", usuarioLogado);

    if (usuarioLogado != null) {
      //console.log("usuarioLogado.role", usuarioLogado.role.toString().substring(0, 18))
      //console.log(" usuarioLogado.role.toString().length ",  usuarioLogado.role.toString().length )
      let usuarioOrganizacao = usuarioLogado.role.length > 18 ? usuarioLogado.role.toString().substring(0, 18) : usuarioLogado.role;

      //if(usuarioLogado.tipoRole == "EXTERNO")
      if (usuarioLogado.role == "ProjetoJovem"  || usuarioOrganizacao == "ProjetoOrganizacao") {//|| usuarioLogado.role == "ProjetoMulher"
        this.showMenu = false;
        this.showDashboard = false;
        this.showManual = false;
      } else if (usuarioLogado.role == "LicitabenFornecedor" || usuarioLogado.role == "LicitabenBeneficiario" || usuarioLogado.role == "FazendoContas" || usuarioLogado.role == "TecnicoAgropolos" || usuarioLogado.role == "AuditoriaExterna") {
        this.showMenu = true;
        this.showDashboard = false;
        this.showManual = false;
      }
      else if (usuarioLogado.role == "ProjetoMulher") {//novo teste luiz
        this.showMenu = false;
        this.showDashboard = false;
        this.showManual = false;
      }
      else if (this.funcionalidade == 'editalmulheresruraiscadastro') {
        this.showMenu = false;
        this.showDashboard = false;
        this.showManual = false;
      } 
      else if (this.funcionalidade == 'licitaben') {
        this.showMenu = false;
        this.showDashboard = false;
        this.showManual = false;
      }
      else if (this.funcionalidade == 'pesquisasatisfacaofeira') {
        this.showMenu = false;
        this.showDashboard = false;
        this.showManual = false;
      }
      else if (this.funcionalidade == 'fazendocontas') {
        this.showMenu = false;
        this.showDashboard = false;
        this.showManual = false;
      }
      else{
        this.showMenu = true;
        this.showDashboard = true;
        this.showManual = false;
      }

      this.sepContainerService.emitEnvironment.subscribe(
        (environment) => {
          //console.log(" this.sepContainerService.emitEnvironment", environment)
          this.environment = Object.assign(this.environment, environment);
          this.environment.tooltip = 'Ambiente de ' + this.environment.name;
        }
      );

      this.sepMatTbService.emitChangedColor.subscribe(changedColor => this.color = changedColor);
      this.sepMatTbService.emitAppTitle.subscribe(changedAppTitle => this.appTitle = changedAppTitle);
    }

    if (this.funcionalidade == "editalmulheresruraiscadastro") {
      this.showMenu = false;
    }
    else if (this.funcionalidade == 'licitaben') {
      this.showMenu = false;
    }
    else if (this.funcionalidade == 'fazendocontas') {
      this.showMenu = false;
    }

    //this.ExibeManuais();
  }

  onClickMenu() {
    this.sepMatSidenavContainerService.toggle();
  }

  onClickDashboard() {
    //this.router.navigate(['/dashboard']);
    window.open("http://is.gd/psjiv_ugp", "_blank");
  }

  onClickDashboardBanco() {
   // window.open("http://is.gd/psjiv", "_blank");
   window.open("https://is.gd/psjivbanco", "_blank");
  }

  onClickDashboardLinhaTempo() {
    window.open("http://is.gd/BcJHox", "_blank");
  }

  onClickPrincipal() {
    this.router.navigate(['/principal']);
  }

  onClickManual(element: any): void {
    const _dialog = this.matdialog.open(
      VideoManualModalComponent,
      {
        width: '700px',
        // height: '600px',
        data: { entidade: element }
      }
    );
    _dialog.afterClosed().subscribe(() => {

    });
  }

  ExibeManuais() {
    this.ELEMENT_DATA = [];

    this.http.get('/Dominio/allManualSistema/GERAL', false).subscribe((list: any[]) => {
      //console.log("ExibeManuais---list",list);
      let dados = list;
      this.lstManuais = [];

      dados.forEach(p => {
        let itemAnexo = {} as Anexo
        itemAnexo.id = p.id,
          itemAnexo.link = this._sanitizer.bypassSecurityTrustResourceUrl(p.descricao),
          itemAnexo.nome = p.nome;
        //itemAnexo.descricao = p.nome;
        //itemAnexo.extensao = p.extensaoArquivo;
        //itemAnexo.tamanho = p.tamanhoArquivo;
        this.ELEMENT_DATA.push(itemAnexo);
      })

      this.lstManuais = this.ELEMENT_DATA;
      this.ELEMENT_DATA = [];
    });
  }

}
