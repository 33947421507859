import { AgropolosMatSidenavService } from '../agropolos-mat-sidenav/agropolos-mat-sidenav.service';
import { Component, OnInit, Input, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';
import { AgropolosMatSidenavItemComponent } from '../agropolos-mat-sidenav-item/agropolos-mat-sidenav-item.component';

@Component({
  selector: 'agropolos-sidenav-expansion-menu',
  templateUrl: './agropolos-mat-sidenav-expansion-menu.component.html',
  styleUrls: ['./agropolos-mat-sidenav-expansion-menu.component.scss']
})
export class AgropolosMatSidenavExpansionMenuComponent implements OnInit {

  @Input() titulo: string;
  @Input() texto: string;
  @Input() botoes: Array<any> = new Array<any>();
  @Input() expanded: boolean;

  @Input() attrNomeBotoes: string;
  @Input() attrCorBotoes: string;
  @Input() attrTipoBotoes: string;
  @Input() attrIconeBotoes: string;
  @Input() attrTextoBotoes: string;
  @Input() attrDesabilitarBotoes: string;

  @Output() clickBotao: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(AgropolosMatSidenavItemComponent)
  agropolosMatSidenavItems: QueryList<AgropolosMatSidenavItemComponent>;

  constructor(
    private sepSidenavService: AgropolosMatSidenavService
  ) { }

  ngOnInit() { }

  hasActions(): boolean {
    return (this.botoes.length !== 0);
  }

  onClick(event: any) {
    this.clickBotao.emit(event);
  }

  btnName(object: any) {
    return this.sepSidenavService.searchInside(object, this.attrNomeBotoes);
  }

  btnType(object) {
    return this.sepSidenavService.searchInside(object, this.attrTipoBotoes);
  }

  btnColor(object) {
    return this.sepSidenavService.searchInside(object, this.attrCorBotoes);
  }

  btnLabel(object) {
    return this.sepSidenavService.searchInside(object, this.attrTextoBotoes);
  }

  btnIcon(object) {
    return this.sepSidenavService.searchInside(object, this.attrIconeBotoes);
  }

  btnDisabled(object) {
    return this.sepSidenavService.searchInside(object, this.attrDesabilitarBotoes);
  }

  possuiUmaDasRoles() {
    const arrItems = this.agropolosMatSidenavItems.toArray();
    return arrItems.some(x => x.possuiUmaDasRoles());
  }
}

