import { Observable ,  Subject } from 'rxjs';
import { AgropolosContentHeader } from './agropolos-mat-sidenav-content.service';
import { Injectable } from '@angular/core';

export interface AgropolosContentHeader {
  titulo: string;
  subtitulo?: string;
  subtituloAbaixo?: boolean;
}

@Injectable()
export class AgropolosMatSidenavContentService {

  public headerChange: Subject<AgropolosContentHeader> = new Subject<AgropolosContentHeader>();

  constructor() { }

  hasHeader(header: AgropolosContentHeader): boolean {
    return (header !== undefined && header.titulo !== undefined && header.titulo.trim() !== '');
  }

  header(header: AgropolosContentHeader) {
    this.headerChange.next({
      titulo: header.titulo,
      subtitulo: (header.subtitulo ? header.subtitulo : '' ),
      subtituloAbaixo: ( header.subtituloAbaixo ? header.subtituloAbaixo : false )
    });
  }

  getHeader(): Observable<AgropolosContentHeader> {
    return this.headerChange.asObservable();
  }
}
