import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ChangePasswordModel } from '../../../models/integra/changepasswordmodel';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  private apiUrl = '';

  constructor(private http: HttpClient) { 
    this.apiUrl = environment.resetPassUrl;
  }

  forgotpassword(email: string) {
    return this.http.get<any>(`${this.apiUrl}/manage/forgotpassword?email=` + email);
  }

  changePassword(model: ChangePasswordModel) {
    return this.http.post<any>(`${this.apiUrl}/manage/changepassword`, model);
  }

}
