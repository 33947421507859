import { Subscription } from 'rxjs';
import { AgropolosContentHeader } from './agropolos-mat-sidenav-content.service';
import { ShellService } from '../../../../core/shell.service';
import { Component, ViewChild, OnDestroy } from '@angular/core';

import { AgropolosMatContainerService } from '../../../components/agropolos-container/agropolos-mat-container.service';

@Component({
  selector: 'agropolos-sidenav-content',
  templateUrl: './agropolos-mat-sidenav-content.component.html',
  styleUrls: ['./agropolos-mat-sidenav-content.component.scss']
})
export class AgropolosMatSidenavContentComponent implements OnDestroy {

  hasHeader = false;
  private contentHeader: AgropolosContentHeader;
  private appVersion: string;
  private selectedTheme: string;
  private headerChange: Subscription;
  private themes = [
    { class: 'agropolos-material-theme', viewValue: 'Agropolos Material Theme' },
    { class: 'agropolos-material-dark-theme', viewValue: 'Agropolos Material Dark Theme' }
  ];

  constructor(private sepContainerService: AgropolosMatContainerService,
    private shellService: ShellService) {

    this.headerChange = this.shellService.content().getHeader().subscribe(
      (header) => {
        if (header !== undefined) {
          this.contentHeader = header;
          this.hasHeader = true;
        } else {
          this.hasHeader = false;
        }
      }
    );
  }

  onChangeTheme() {
    this.sepContainerService.defineTheme(this.selectedTheme);
  }

  subtitleClass(): string {
    return (this.contentHeader.subtituloAbaixo ? 'sub-on-bottom' : 'sub-on-right');
  }

  ngOnDestroy() {
    this.headerChange.unsubscribe();
  }

}
