import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
// tslint:disable-next-line: directive-selector
@Directive({ selector: '[maskNumber]' })
export class MaskNumberDirective {
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPressEnter: EventEmitter<any> = new EventEmitter();
  arrayNumber: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  arrayFunction: any[] = [, 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
  public el: HTMLElement;
  constructor(public element: ElementRef) {
    this.el = this.element.nativeElement;
  }
  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const htlmElement = (<HTMLInputElement>this.el);
    if (event.key === 'Enter') {
      this.onPressEnter.emit();
    } else if (this.arrayFunction.indexOf(event.key) < 0) {
      htlmElement.value = this.convertToNumber(htlmElement.value);
    }
  }
  private convertToNumber(num) {
    if (num) {
      num = num.toString();
      num = num.replace(/\D/g, '');
    }
    return num;
  }
}
