import { ShellService } from "../core/shell.service";
import { AuthenticatedUser } from "../models/authenticated-user";
import { AgropolosAlertComponent, FlashMessageObject } from "./components/agropolos-alert/agropolos-alert.component";
import { CredenciaisService } from "../core/credenciais.service";
import { environment } from "../../environments/environment";
import { Component, OnInit, ViewChild } from "@angular/core";
import * as strings from "../../assets/strings";

@Component({
  templateUrl: "./shell.component.html"
})
export class ShellComponent implements OnInit {
  env = {
    envName: "",
    production: false
  };

  user: AuthenticatedUser;
  public perfilUsuario:string;
  public nomeUsuario:string;
  public emailUsuario:string;
  public secretaria:string;

  @ViewChild("alertMessage", { static: true })
  agropolosAlert: AgropolosAlertComponent;

  ngOnInit() {
    this.env.envName = environment.envName;
    this.env.production = environment.production;
  }

  constructor(
    public credenciaisService: CredenciaisService,
    private shellService: ShellService
  ) {
    this.shellService
      .alert()
      .alertChange.subscribe((flashMessageObject: FlashMessageObject) =>
        this.agropolosAlert.open(flashMessageObject)
      );

    var userString = this.credenciaisService.User
    this.user = JSON.parse(userString)
    //console.log("userString",userString);
    //console.log("this.user",this.user);
    
    if(this.user)
    {
      //console.log("this.user.secretaria",this.user.secretaria['descricao']);
      this.perfilUsuario= this.user.role;
      this.nomeUsuario= this.user.userName;
      this.emailUsuario= this.user.email;
      this.secretaria= this.user.secretaria['descricao'];//this.user.secretaria.descricao;
    }
  }

  public appTitle(): string {
    return strings.sigla;
  }
}
