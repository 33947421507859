import { BaseModel } from "../base-model";

export class ListaPresencaEvento extends BaseModel {

  constructor(
      public id: number,
      public chaveTabelaId: number,
      public tipoEvento: string,
      public cpf: string,
      public nome: string,
      public email: string,
      public instituicao: string,
      public telefone: string,
      public assinatura: string,
      public dataAssinatura: Date,
      public dataEnvioEmail: Date,
      public envioEmail: boolean,      

  ) { super(); }

}
