import { Component, OnInit, OnDestroy, Input, AfterContentInit, OnChanges, SimpleChanges, QueryList, ContentChildren, SimpleChange, Output, EventEmitter } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { GridSettings } from '../../../../models/GridSettings';
import { AgropolosGridFilterColumnComponent } from './agropolos-grid-filter-column/agropolos-grid-filter-column.component';
import { Router } from '@angular/router';

@Component({
  selector: 'agropolos-grid-filter',
  templateUrl: './agropolos-grid-filter.component.html',
  styleUrls: ['./agropolos-grid-filter.component.scss']
})
export class AgropolosGridFilterComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {
  constructor(
    private _router: Router
    ) { }

  // public gridSettingsModel: GridSettings;
  // @ContentChildren(AgropolosGridFilterColumnComponent) columns: QueryList<AgropolosGridFilterColumnComponent>;

  @Input() urlCreate: string;

  @Output() notifyCreate: EventEmitter<'create'> = new EventEmitter();
  @Output() notifySearch: EventEmitter<'search'> = new EventEmitter();
  @Output() notifyClear: EventEmitter<'clear'> = new EventEmitter();

  @Input()
  get disableCreate(): boolean { return this._disableCreate; }
  set disableCreate(disableCreate: boolean) {
    this._disableCreate = coerceBooleanProperty(disableCreate);
  }
  _disableCreate: boolean;

  @Input()
  get disableSearch(): boolean { return this._disableSearch; }
  set disableSearch(disableSearch: boolean) {
    this._disableSearch = coerceBooleanProperty(disableSearch);
  }
  _disableSearch: boolean;

  @Input()
  get disableClear(): boolean { return this._disableClear; }
  set disableClear(disableClear: boolean) {
    this._disableClear = coerceBooleanProperty(disableClear);
  }
  _disableClear: boolean;

  @Input()
  get accordion(): boolean { return this._accordion; }
  set accordion(accordion: boolean) {
    this._accordion = coerceBooleanProperty(accordion);
  }
  _accordion: boolean;

  ngOnInit() {
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.gridSettingsModel = changes.gridSettingsModel.currentValue;
    // this.columns.map(c => {
    //   c.gridSettingsModel = this.gridSettingsModel;
    //   c.ngOnChanges({
    //     gridSettingsModel: new SimpleChange(null, this.gridSettingsModel, true)
    //   });
    // });
  }

  public create(): void {
    if (this.urlCreate) {
      this._router.navigate([this.urlCreate]);
    } else {
      this.notifyCreate.emit();
    }
  }

  public search(): void {
    this.notifySearch.emit();
  }

  public clear() {
    this.notifyClear.emit();
  }
}
