import { Component, OnInit, OnDestroy, Input, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { GridSettings } from '../../../../../../models/GridSettings';
import { AgropolosGridFilterInput } from '../agropolos-grid-filter-input';
import { Data } from '../../../../../../core/providers/data';
import { WHERE_OPERATION_FILTER } from '../../../../../../models/enums/where-operation-enum';

@Component({
  selector: 'agropolos-grid-filter-input-text',
  templateUrl: './agropolos-grid-filter-input-text.component.html',
  styleUrls: ['./agropolos-grid-filter-input-text.component.scss'],
  providers: [{ provide: AgropolosGridFilterInput, useExisting: forwardRef(() => AgropolosGridFilterInputTextComponent) }]
})
export class AgropolosGridFilterInputTextComponent extends AgropolosGridFilterInput implements OnInit, OnDestroy, OnChanges {
  constructor(public data: Data) {
    super();
  }

  @Input() placeholder: string;
  @Input() ngModelProperty: string;
  @Input() field: string;
  @Input() op = WHERE_OPERATION_FILTER.cn;

  ngOnInit() {
    this.gridSettingsModel = this.data.gridSettings;
    this.entityFilter = this.data.entityFilter;
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.gridSettingsModel = changes.gridSettingsModel.currentValue;
  }
}
