import { GridSettings } from '../../../../../models/GridSettings';
import { SimpleChanges } from '@angular/core';

export class AgropolosGridFilterInput {
  constructor() { }

  public entityFilter: any;
  public gridSettingsModel: GridSettings;

  // tslint:disable-next-line:use-life-cycle-interface
  public ngOnChanges(changes: SimpleChanges) {
    // this.gridSettingsModel = changes.gridSettingsModel.currentValue;
  }
}
