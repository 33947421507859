import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appMaskHour]'
})
export class MaskHourDirective implements OnDestroy {

  //mask = [/((\d{4})-(\d{2})-(\d{2})|(\d{2})\/(\d{2})\/(\d{4}))/]; // dd/mm/yyyy
  mask = [/[0-9]/, /\d/,':',/\d/, /\d/]; // hh:mm

  maskedInputController;

  constructor(private element: ElementRef) {
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

}
