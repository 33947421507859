import { CredenciaisService } from '../../../../core/credenciais.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '../../../../core/local-storage.service';
import { ActivatedRoute, Router } from "@angular/router";
import { replace } from 'lodash';

@Component({
  selector: 'agropolos-sidenav-item',
  templateUrl: './agropolos-mat-sidenav-item.component.html',
  styleUrls: ['./agropolos-mat-sidenav-item.component.scss']
})
export class AgropolosMatSidenavItemComponent implements OnInit {

  @Input() titulo: string;
  @Input() texto: string;
  @Input() quantidade: string;
  @Input() icone: string;
  @Input() posicaoIcone = 'prefixo';
  @Input() iconeMaterial = true;
  @Input() rota: string;
  @Input() disabled = false;
  @Input() disableRipple = false;
  @Input() roles = new Array<string>();
  @Input() visible = true;

  @Input() checkbox = false;
  @Input('checkbox.id') checkboxId: string;
  @Input('checkbox.checked') checked = false;
  @Input('checkbox.required') required = false;
  @Input('checkbox.indeterminate') indeterminate = false;

  @Output('click') click: EventEmitter<any> = new EventEmitter<any>();
  @Output('change') change: EventEmitter<any> = new EventEmitter<any>();
  @Output('check') check: EventEmitter<any> = new EventEmitter<any>();
  @Output('indeterminateChange') indeterminateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private credenciaisService: CredenciaisService,private localStorageService: LocalStorageService,private router: Router) { }

  ngOnInit() {
  }

  hasIcon() {
    return (this.icone !== undefined);
  }

  hasTitle() {
    return (this.titulo !== undefined);
  }

  hasQuantidade() {
    return (this.quantidade !== undefined);
  }

  isPrefix(): boolean {
    return this.posicaoIcone === 'prefixo';
  }

  isSuffix(): boolean {
    return this.posicaoIcone === 'sufixo';
  }

  isIconMaterial(): boolean {
    return this.iconeMaterial;
  }

  isIconPrefix(): boolean {
    return (this.hasIcon() && this.isPrefix() && !this.checkbox && this.isIconMaterial());
  }

  isIconSuffix(): boolean {
    return (this.hasIcon() && this.isSuffix() && !this.checkbox && this.isIconMaterial());
  }

  onClick(event: any) {
    this.click.emit(event);
    this.localStorageService.setObject('gridSettingsModel', null);
    //this.localStorageService.setObject('licitabenLicitacao', null);
    //window.location.reload();
    //let currentUrl = event.srcElement.href;//replace(event.srcElement.href,"http://","");
    //this.router.navigateByUrl(currentUrl);
    //window.location.reload();
  }

  onChange(event: any) {
    this.change.emit(event);
  }

  onCheck(event: any) {
    this.check.emit(event);
  }

  onIndeterminateChange($event: any) {
    this.indeterminateChange.emit(event);
  }

  possuiUmaDasRoles() {
    return this.credenciaisService.possuiUmaDasRoles(this.roles);
  }
}

class SepSidenavItem {
  titulo: string;
  texto: string;
  icone: string;
  posicaoIcone = 'prefixo';
  iconeMaterial = true;
  rota: string;
  checkbox = false;
}

