import { LoginRoutingModule } from './login-routing.module';
import { LoginService } from './login.service';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { MaterialModulos } from '../../../material-modulos.module';
import { AgropolosMaterialModule } from '../../agropolos-material.module';

@NgModule({
  imports: [AgropolosMaterialModule, LoginRoutingModule, MaterialModulos],
  declarations: [LoginComponent],
  exports: [LoginComponent],
  providers: [LoginService]
})
export class LoginModule { }
