import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'agropolos-card',
  templateUrl: './agropolos-card.component.html',
  styleUrls: ['./agropolos-card.component.scss']
})
export class AgropolosCardComponent implements OnInit {

  @Input() hasDivider : boolean = false;
  @Input() animation: string = 'fadeIn';
  
  constructor() { }

  ngOnInit() {
  }

}
