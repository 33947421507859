import { ShellService } from './shell.service';
import { NavegacaoService } from './navegacao.service';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';

import { RestfulService } from './restful.service';
import { ModalLogoffService } from './modal-logoff.service';
import { LocalStorageService } from './local-storage.service';
import { HttpBaseService } from './http-base.service';
import { CredenciaisService } from './credenciais.service';
import { ModalConfirmacaoService } from './modal-confirmacao.service';
import { RoutingStateService } from './routing-state.service';

@NgModule({
  providers: [
    CredenciaisService,
    HttpBaseService,
    LocalStorageService,
    ModalLogoffService,
    NavegacaoService,
    ShellService,
    ModalConfirmacaoService,
    RoutingStateService,
    RestfulService,
    { provide: 'API_URL_OVERRIDE', useValue:  'http://api' },
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule já foi carregado. Importe-o somente do AppModule.');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule
    };
  }
}
