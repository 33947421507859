import { LatinizePipe } from './pipes/latinize.pipe';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighlightDirective } from './directives/highlight.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { MaskPipe } from './pipes/mask.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap';
import { AgropolosCardComponent } from './components/agropolos-card/agropolos-card.component';
import { AgropolosCardHeaderComponent } from './components/agropolos-card/agropolos-card-header/agropolos-card-header.component';
import { AgropolosCardHeaderActionsComponent } from './components/agropolos-card/agropolos-card-header/agropolos-card-header-actions/agropolos-card-header-actions.component';
import { AgropolosCardContentComponent } from './components/agropolos-card/agropolos-card-content/agropolos-card-content.component';
import { AgropolosCardActionsComponent } from './components/agropolos-card/agropolos-card-actions/agropolos-card-actions.component';
import { GridSettingsDirective } from './directives/grid-settings.directive';
import { MaskCpfCnpjDirective } from './directives/mask-cpf-cnpj.directive';
import { MaskNumberDirective } from './directives/mask-number.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgropolosGridComponent } from './components/agropolos-grid/agropolos-grid.component';
import { AgropolosGridTableComponent } from './components/agropolos-grid/agropolos-grid-table/agropolos-grid-table.component';
import { AgropolosGridColumnComponent } from './components/agropolos-grid/agropolos-grid-column/agropolos-grid-column.component';
import { AgropolosGridFilterComponent } from './components/agropolos-grid/agropolos-grid-filter/agropolos-grid-filter.component';
import { AgropolosGridFilterColumnComponent } from './components/agropolos-grid/agropolos-grid-filter/agropolos-grid-filter-column/agropolos-grid-filter-column.component';
import { AgropolosGridFilterInputTextComponent } from './components/agropolos-grid/agropolos-grid-filter/agropolos-grid-filter-input/agropolos-grid-filter-input-text/agropolos-grid-filter-input-text.component';
import { AgropolosGridFilterInputSelectComponent } from './components/agropolos-grid/agropolos-grid-filter/agropolos-grid-filter-input/agropolos-grid-filter-input-select/agropolos-grid-filter-input-select.component';
import { AgropolosGridFilterInputAutocompleteComponent } from './components/agropolos-grid/agropolos-grid-filter/agropolos-grid-filter-input/agropolos-grid-filter-input-autocomplete/agropolos-grid-filter-input-autocomplete.component';
import { AgropolosGridFilterInputDatepickerComponent } from './components/agropolos-grid/agropolos-grid-filter/agropolos-grid-filter-input/agropolos-grid-filter-input-datepicker/agropolos-grid-filter-input-datepicker.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { AgropolosPaginatorComponent } from './utils/agropolos-paginator';
import { MatDialogModule } from '@angular/material';
// import { FileUploadModule } from 'ng2-file-upload';
import { MaskCpfDirective } from './directives/mask-cpf.directive';
import { MaskCnpjDirective } from './directives/mask-cnpj.directive';
import { MaskDateDirective } from './directives/mask-date.directive';
import { MaskHourDirective } from './directives/mask-hour.directive';
import { MaskPercentDirective } from './directives/mask-percent.directive';
import { MaskPhoneDirective } from './directives/mask-phone.directive';
import { MaskCepDirective } from './directives/mask-cep.directive';
import { SafeHtml } from './pipes/linkbase64.pipe';
import { CNPJPipe } from './pipes/cnpj.pipe';
import { CPFPipe } from './pipes/cpf.pipe';
import { TelefonePipe } from './pipes/telefone.pipe';
import { CepPipe } from './pipes/cep.pipe';
import { NgxMaskModule } from 'ngx-mask'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AcoesDirective } from './directives/acoes.directive';
import { AgropolosFileUploadComponent } from './components/agropolos-file-upload/agropolos-file-upload.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DisableAfterClickDirective } from './directives/disable-after-click.directive';
import { UpperCaseInputDirective } from './directives/mask-upper.directive';
import { MaskNumberTwoDecDirective } from './directives/mask-number-two.directive';
import { LowerCaseInputDirective } from './directives/mask-lower.directive';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    // FileUploadModule,
    MatDialogModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),

  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    ModalModule,
    AgropolosCardComponent,
    AgropolosCardHeaderComponent,
    AgropolosCardHeaderActionsComponent,
    AgropolosCardContentComponent,
    AgropolosCardActionsComponent,
    HighlightDirective,
    GridSettingsDirective,
    MaskCpfCnpjDirective,
    MaskNumberDirective,
    MaskCpfDirective,
    MaskCnpjDirective,
    AcoesDirective,
    CapitalizePipe,
    LatinizePipe,
    MaskPipe,
    FlexLayoutModule,
    AgropolosGridComponent,
    AgropolosGridTableComponent,
    AgropolosGridColumnComponent,
    AgropolosGridFilterComponent,
    AgropolosGridFilterColumnComponent,
    AgropolosGridFilterInputTextComponent,
    AgropolosGridFilterInputSelectComponent,
    AgropolosGridFilterInputAutocompleteComponent,
    AgropolosGridFilterInputDatepickerComponent,
    MaskDateDirective,
    MaskHourDirective,
    UpperCaseInputDirective,
    LowerCaseInputDirective,
    MaskPercentDirective,
    MaskPhoneDirective,
    MaskCepDirective,
    MaskNumberTwoDecDirective,
    SafeHtml,
    CNPJPipe,
    CPFPipe,
    TelefonePipe,
    CepPipe,
    NgxMaskModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule,
    DisableAfterClickDirective

    ],
  declarations: [
    AgropolosCardComponent,
    AgropolosCardHeaderComponent,
    AgropolosCardHeaderActionsComponent,
    AgropolosCardContentComponent,
    AgropolosCardActionsComponent,
    HighlightDirective,
    GridSettingsDirective,
    MaskCpfCnpjDirective,
    MaskNumberDirective,
    MaskCpfDirective,
    MaskCnpjDirective,
    AcoesDirective,
    CapitalizePipe,
    LatinizePipe,
    MaskPipe,
    AgropolosGridComponent,
    AgropolosGridTableComponent,
    AgropolosGridColumnComponent,
    AgropolosGridFilterComponent,
    AgropolosGridFilterColumnComponent,
    AgropolosGridFilterInputTextComponent,
    AgropolosGridFilterInputSelectComponent,
    AgropolosGridFilterInputAutocompleteComponent,
    AgropolosGridFilterInputDatepickerComponent,
    MaskDateDirective,
    MaskHourDirective,
    UpperCaseInputDirective,
    LowerCaseInputDirective,
    MaskPercentDirective,
    MaskPhoneDirective,
    MaskCepDirective,
    MaskNumberTwoDecDirective,
    SafeHtml,
    CNPJPipe,
    CPFPipe,
    TelefonePipe,
    CepPipe,
    AgropolosFileUploadComponent,
    DisableAfterClickDirective
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: AgropolosPaginatorComponent }
  ]

})
export class SharedModule { }
