import { NgModule } from '@angular/core';

import { ChangePasswordComponent } from './change-password.component';
import { AgropolosMaterialModule } from '../../agropolos-material.module';
import { ChangePasswordService } from './change-password.service';
import { ChangePasswordRoutingModule } from './change-password-routing.module';
import { MaterialModulos } from '../../../material-modulos.module';


@NgModule({
  imports: [AgropolosMaterialModule, ChangePasswordRoutingModule, MaterialModulos],
  declarations: [ChangePasswordComponent],
  exports: [ChangePasswordComponent],
  entryComponents: [ChangePasswordComponent],
  providers: [ChangePasswordService]
})
export class ChangePasswordModule { }
