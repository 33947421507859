import { LocalStorageService } from '../../../../core/local-storage.service';
import { preparseElement } from '@angular/compiler/src/template_parser/template_preparser';
import { Component, OnInit, Input, ViewChild, EventEmitter } from '@angular/core';

import { AgropolosMatSidenavService } from './agropolos-mat-sidenav.service';

@Component({
  selector: 'agropolos-sidenav',
  templateUrl: './agropolos-mat-sidenav.component.html',
  styleUrls: ['./agropolos-mat-sidenav.component.scss']
})
export class AgropolosMatSidenavComponent implements OnInit {

  fixMenu = false;

  constructor(private sepMatSidenavService: AgropolosMatSidenavService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.fixMenu = this.localStorageService.getObject('navbar-fixed');
  }

  toggleEvent() {
    this.localStorageService.setObject('navbar-fixed', !this.fixMenu);
  }

}
