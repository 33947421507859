import { Directive, OnDestroy, ElementRef, OnInit, Input, HostListener } from '@angular/core';
import { CredenciaisService } from '../../core/credenciais.service';
import { ActivatedRoute } from '@angular/router';

@Directive({selector: '[appAcoes]'})

export class AcoesDirective implements OnInit {

  funcionalidade: string;

  constructor(private element: ElementRef,
              private route: ActivatedRoute,
              private credenciaisService: CredenciaisService) { 
                this.funcionalidade = this.route.snapshot['_routerState'].url.replace('/', '').split('/', 1)[0]
  }

  @Input() appAcao: string;

  public ngOnInit() {
    var funcionalidadesstring = this.credenciaisService.Funcionalidades
    var funcionalidades = JSON.parse(funcionalidadesstring)
    if (!this.permitir(funcionalidades, this.funcionalidade, this.appAcao) || 
         this.permitir(funcionalidades, this.funcionalidade, this.appAcao).length == 0) {
      this.element.nativeElement.style.display = 'none';
    } else {
      this.element.nativeElement.style.display = '';
    }
    
  }

  private permitir(funcionalidades: any, funcionalidade: string, acao: string) {
    //console.log("permitir--appAcao--permitir--funcionalidades", funcionalidades)
    
    if(funcionalidade != "editalmulheresruraiscadastro"){
      return funcionalidades.filter(function(data) {
        //console.log("ngOnInit--appAcao--permitir--data", data)
        //console.log("permitir--appAcao--permitir--data.descricao", data.descricao)
        //console.log("permitir--appAcao--permitir--funcionalidade", funcionalidade)
        //console.log("permitir--appAcao--permitir--acao", acao)
        //console.log("permitir--(data.descricao.indexOf(funcionalidade) >=0)", (data.descricao.indexOf(funcionalidade) >=0))

        let fun_desc = (data.descricao.indexOf(funcionalidade) >=0) || (funcionalidade.indexOf(data.descricao) >=0);
        let acao_ok = data.acao == acao;

        return fun_desc && acao_ok;      //return data.descricao == funcionalidade && data.acao == acao;      
      })
    }    
  }
}
