import { Component, Input, OnDestroy, OnInit, Inject, HostListener, AfterViewInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpBaseService } from "../../../../core/http-base.service";
import { RoutingStateService } from "../../../../core/routing-state.service";
import { ShellService } from "../../../../core/shell.service";
import { AgendamentoDizListaPresenca } from "../../../../models/integra/agendamento-diz-lista-presenca";
import { ListaPresencaEventoService } from "../lista-presenca-evento.service";

@Component({
  templateUrl: './assinatura-dialog.component.html',
  styleUrls: ['./assinatura-dialog.component.scss'],
})

export class ListaPresencaEmailDialogComponent implements OnInit,  AfterViewInit {
  @HostListener('document:mousedown', ['$event'])
  pressMouseEventHandler(event: MouseEvent) {
    this.pressEventHandler(event);
  }

  @HostListener('document:touchstart', ['$event'])
  pressTouchEventHandler(event: TouchEvent) {
    this.pressEventHandler(event);
  }

  @HostListener('document:mousemove', ['$event'])
  mouseDragEventHandler(event: MouseEvent) {
    this.dragEventHandler(event);
  }

  @HostListener('document:touchmove', ['$event'])
  touchDragEventHandler(event: TouchEvent) {
    this.dragEventHandler(event);
  }

  @HostListener('document:mouseup', ['$event'])
  mouseReleaseEventHandler(event: MouseEvent) {
    this.releaseEventHandler();
  }

  @HostListener('document:touchend', ['$event'])
  touchReleaseEventHandler(event: TouchEvent) {
    this.releaseEventHandler();
  }

  @HostListener('document:mouseout', ['$event'])
  cancelMouseEventHandler() {
    this.cancelEventHandler();
  }

  @HostListener('document:touchcancel', ['$event'])
  cancelTouchEventHandler() {
    this.cancelEventHandler();
  }

  //regras assinatura
  private _canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private paint: boolean;

  private clickX: number[] = [];
  private clickY: number[] = [];
  private clickDrag: boolean[] = [];

  public entity: AgendamentoDizListaPresenca;

  selectedTabIndex: number;
  tabindex: any;
  public id: number;
  public formGroup: FormGroup;
  public formGroupIsValid = false;
  public AgendamentoDizListaPresencaId: number;

  checked = false;
  disabled = false;
  public blockInput: boolean = false;

  //#endregion

  constructor(
    private http: HttpBaseService,
    private route: ActivatedRoute,
    private shellService: ShellService,
    private formBuilder: FormBuilder,
    private router: Router,
    private routingStateService: RoutingStateService,
    private _listaPresencaEmailService: ListaPresencaEventoService,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<ListaPresencaEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.id = this.route.snapshot.params.id;
    this.tabindex = 0;
    this.initEntity();
    this.createFormValidators();

    this.formGroup.valueChanges.subscribe(value => {
    });

  }

  ngAfterViewInit() {
    //console.log("ngAfterViewInit",this.route.snapshot.params.id)
    let _canvas = document.getElementById('canvas') as HTMLCanvasElement;
    let context = _canvas.getContext('2d');
    let image = document.getElementById('source');

    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 1;

    this._canvas = _canvas;
    this.context = context;

    this.redraw();
    this.createUserEvents();
  }

  ngOnInit(): void {
    if (this.data.dataKey.id) {
      this.entity = this.data.dataKey;
      //console.log("ngOnInit---this.data",this.data);
      this.createFormValidators();        
    }
  }

  //ngOnDestroy(): void { }

  private initEntity(): void {
    this.entity = new AgendamentoDizListaPresenca(null,null,null, null, null, null, null, null,null, null,null, null);
  }

  private createFormValidators(): void {
    this.formGroup = this.formBuilder.group({
      id: new FormControl({ value: this.entity.id, disabled: true }),
      email: new FormControl({ value: this.entity.email, disabled: false}),
    });
  }

  private prepareToSave(formModel: AgendamentoDizListaPresenca): void {
    this.entity = { ...this.entity, ...formModel };
  }

  // public save(formModel: AgendamentoDizListaPresenca, isValid: boolean): void {
  //   this.prepareToSave(formModel);
  //   this.entity.email = this.formGroup.controls['email'].value;

  //   this.http.post('/AgendamentoDizListaPresenca/ReenviaEmailAcesso', true, this.entity).subscribe((dado) => {   
  //     this.shellService.alert().success({ messages: ["Alterado Email!"], timeout: 5000 });
  //     setTimeout(() => this.goBack(), 3000);
  //     // this.http.get('/ServicoAutomatizado/envioemailacessojovem?idJovem='+this.entity.id, false).subscribe((data: any) => {
  //     //   //console.log("verificaRotinaTramitacaoLicitacao",retorno)
  //     //   this.shellService.alert().success({ messages: ["Reenviado Email de Acesso!"], timeout: 5000 });
  //     //   this.goBack();
  //     //  });   

  //   });

  // }

  public goBack(): void {
    this.thisDialogRef.close();
  }

  //METODOS DE ASSINATURA
  private createUserEvents() {
    let _canvas = this._canvas;

    document
      .getElementById('clear')
      .addEventListener('click', this.clearEventHandler);
  }

  private redraw() {
    let clickX = this.clickX;
    let context = this.context;
    let clickDrag = this.clickDrag;
    let clickY = this.clickY;
    for (let i = 0; i < clickX.length; ++i) {
      context.beginPath();
      if (clickDrag[i] && i) {
        context.moveTo(clickX[i - 1], clickY[i - 1]);
      } else {
        context.moveTo(clickX[i] - 1, clickY[i]);
      }

      context.lineTo(clickX[i], clickY[i]);
      context.stroke();
    }
    context.closePath();
  }

  private addClick(x: number, y: number, dragging: boolean) {
    this.clickX.push(x);
    this.clickY.push(y);
    this.clickDrag.push(dragging);
  }

  private clearCanvas() {
    this.context.clearRect(0, 0, this._canvas.width, this._canvas.height);
    this.clickX = [];
    this.clickY = [];
    this.clickDrag = [];
  }

  private clearEventHandler = () => {
    this.clearCanvas();
  };

  private releaseEventHandler = () => {
    this.paint = false;
    this.redraw();
  };

  private cancelEventHandler = () => {
    this.paint = false;
  };

  private pressEventHandler(e: MouseEvent | TouchEvent) {
    let mouseX = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageX
      : (e as MouseEvent).pageX;
    let mouseY = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageY
      : (e as MouseEvent).pageY;
    mouseX -= this._canvas.offsetLeft;
    mouseY -= this._canvas.offsetTop;

    this.paint = true;
    this.addClick(mouseX, mouseY, false);
    this.redraw();
  }

  private dragEventHandler(e: MouseEvent | TouchEvent) {
    let mouseX = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageX
      : (e as MouseEvent).pageX;
    let mouseY = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageY
      : (e as MouseEvent).pageY;
    mouseX -= this._canvas.offsetLeft;
    mouseY -= this._canvas.offsetTop;

    if (this.paint) {
      this.addClick(mouseX, mouseY, true);
      this.redraw();
    }

    e.preventDefault();
  }


}
