import { AgropolosDialogComponent } from './agropolos-dialog.component';
import { asNativeElements, ComponentFactoryResolver, EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import * as strings from '../../../../assets/strings';

@Injectable()
export class AgropolosDialogService {

  private dialogConfig: MatDialogConfig = {
    data: {},
    viewContainerRef: null
  };

  private dialogMap = {
    'success': { title: 'Sucesso!', icon: 'check_circle', actions: [{ name: 'OK', closeResult: true }], alignButtons: 'center' },
    'info': { title: 'Importante:', icon: 'info', actions: [{ name: 'OK', closeResult: true }], alignButtons: 'center' },
    'warning': { title: 'Aviso!', icon: 'warning', actions: [{ name: 'OK', closeResult: true }], alignButtons: 'center' },
    'error': { title: 'Erro:', icon: 'error', actions: [{ name: 'OK', closeResult: true }], alignButtons: 'center' },
    'confirm': { title: 'Confirmação:', icon: 'help', actions: [{ name: 'Cancelar', closeResult: false }, { name: 'OK', closeResult: true }] },
    'wait': { title: `Por favor, aguarde...`, isAlert: false },
    undefined: { title: `${strings.nomeAPP}:`, actions: [{ name: 'OK' }], alignButtons: 'center' }
  };

  constructor(private dialog: MatDialog, private cfr: ComponentFactoryResolver) { }

  open(dialogDataOptions?: any): MatDialogRef<AgropolosDialogComponent> {

    if (this.dialogConfig.viewContainerRef == null) {
      //console.log('Erro: ViewContainerRef em AgropolosDialogService não pode ser null.');
      return;
    }

    this.dialogConfig.width = null;
    this.dialogConfig.width = null;
    if (dialogDataOptions.width) {
      this.dialogConfig.width = dialogDataOptions.width;
    }
    if (dialogDataOptions.height) {
      this.dialogConfig.height = dialogDataOptions.height;
    }

    this.initDialogConfig(dialogDataOptions.alertType);

    // Concatena configurações default com aquelas informadas pelo programador:
    const mergedData = { ...this.dialogConfig.data, ...dialogDataOptions };
    // Cria DialogRef com base nas configurações do dialog.
    const dialogRef = this.dialogRef(this.dialogConfig);
    // Atualiza os dados de DialogRef
    dialogRef.componentInstance.data = mergedData;

    dialogRef.afterClosed().subscribe(
      () => { dialogRef.close(); }
    );

    return dialogRef;
  }

  close(dialogRef: MatDialogRef<AgropolosDialogComponent>) {
    dialogRef.close();
  }

  private initDialogConfig(type: string): MatDialogConfig {
    const dMap = this.dialogMap[type];

    if (type === 'wait') {
      this.dialogConfig.width = '255px';
      this.dialogConfig.height = '200px';
      this.dialogConfig.disableClose = true;
    }

    this.dialogConfig.data = {
      title: dMap.title,
      content: '',
      actions: dMap.actions,
      isAlert: (type || (type === undefined)), // (default) = true;
      alignButtons: dMap.alignButtons
    };
    return this.dialogConfig;
  }

  private dialogRef(finalDialogConfig: MatDialogConfig): MatDialogRef<AgropolosDialogComponent> {
    return this.dialog.open(AgropolosDialogComponent, finalDialogConfig);
  }

  public get viewContainerRef() {
    return this.dialogConfig.viewContainerRef;
  }

  public set viewContainerRef(viewContainerRef: ViewContainerRef) {
    this.dialogConfig.viewContainerRef = viewContainerRef;
  }

}
