import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'agropolos-card-content',
  templateUrl: './agropolos-card-content.component.html',
  styleUrls: ['./agropolos-card-content.component.scss']
})
export class AgropolosCardContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
