import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[maskCnpj]' })
export class MaskCnpjDirective {

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPressEnter: EventEmitter<any> = new EventEmitter();

  arrayNumber: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  arrayFunction: any[] = [, 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

  public el: HTMLElement;
  constructor(public element: ElementRef) {
    this.el = this.element.nativeElement;
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const htlmElement = (<HTMLInputElement>this.el);
    if (event.key === 'Enter') {
      this.onPressEnter.emit();
    } else if (this.arrayFunction.indexOf(event.key) < 0) {
      htlmElement.value = this.convertToCnpj(htlmElement.value);
    }
  }

  private convertToCnpj(num) {
    if (num) {
      num = num.toString();
      num = num.replace(/\D/g, '');
      const aux: number = num.length;

      switch (true) {
        case aux === 4:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case aux === 5:
          num = num.replace(/(\d+)(\d{2})/, '$1.$2');
          break;
        case aux === 6:
          num = num.replace(/(\d+)(\d{2})/, '$1.$2');
          break;
        case aux === 7:
          num = num.replace(/(\d+)(\d{2})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 8:
          num = num.replace(/(\d+)(\d{2})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 9:
          num = num.replace(/(\d+)(\d{2})(\d{3})/, '$1.$2.$3');
          break;
        case aux === 10:
          num = num.replace(/(\d+)(\d{2})(\d{3})(\d{1})/, '$1.$2.$3-$4');
          break;
        case aux === 11:
          num = num.replace(/(\d+)(\d{2})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          break;
        case aux === 12:
          num = num.replace(/(\d+)(\d{2})(\d{3})(\d{4})/, '$1.$2.$3/$4');
          break;
        case aux === 13:
          num = num.replace(/(\d+)(\d{2})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          break;
        case aux === 14:
          num = num.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
          break;
        case aux > 14:
          num = (num.substring(0, num.length - 1)).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
          break;
      }
    }
    return num;
  }

  public validarCnpj(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '') return false;
    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') return false;

    // Valida DVs
    let tamanho: number = cnpj.length - 2;
    let numeros: any = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i: number = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado: number = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i: number = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
          return false;
    return true;
  }
}
