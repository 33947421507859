import { Component, OnInit, Input, QueryList, ContentChildren } from '@angular/core';
import { AgropolosMatSidenavItemComponent } from '../agropolos-mat-sidenav-item/agropolos-mat-sidenav-item.component';
import { AgropolosMatSidenavExpansionMenuComponent } from '../agropolos-mat-sidenav-expansion-menu/agropolos-mat-sidenav-expansion-menu.component';

@Component({
  selector: 'agropolos-sidenav-section',
  templateUrl: './agropolos-mat-sidenav-section.component.html',
  styleUrls: ['./agropolos-mat-sidenav-section.component.scss']
})
export class AgropolosMatSidenavSectionComponent implements OnInit {

  @Input() titulo: string;
  @Input() icone: string;
  @Input() expanded: boolean;

  @ContentChildren(AgropolosMatSidenavItemComponent)
  agropolosMatSidenavItems: QueryList<AgropolosMatSidenavItemComponent>;

  @ContentChildren(AgropolosMatSidenavExpansionMenuComponent)
  agropolosMatSidenavExpansionMenus: QueryList<AgropolosMatSidenavExpansionMenuComponent>;

  constructor() { }

  ngOnInit() {

  }

  possuiUmaDasRoles() {
    const arrItems = this.agropolosMatSidenavItems.toArray();
    const menus = this.agropolosMatSidenavExpansionMenus.toArray();
    return arrItems.some(x => x.possuiUmaDasRoles()) || menus.some(x => x.possuiUmaDasRoles());
  }
}
