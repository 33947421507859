import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'agropolos-visualizar-documento',
    templateUrl: './visualizar-documento.component.html',
    styleUrls: ['./visualizar-documento.component.css']
})
export class VisualizarDocumentoComponent implements OnInit {
    constructor(public thisDialogRef: MatDialogRef<VisualizarDocumentoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
    ngOnInit() {
    }
    onCloseConfirm() {
        this.thisDialogRef.close('Confirm');
    }
    onCloseCancel() {
        this.thisDialogRef.close('Cancel');
    }
}
