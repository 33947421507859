import { Injectable } from '@angular/core';
import { JwtHelper } from '../shared/utils/jwt-helper';
import { AuthenticatedUser } from '../models/authenticated-user';
import { LocalStorageService } from './local-storage.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable()
export class CredenciaisService {

  private authenticatedUser: AuthenticatedUser;

  constructor(private localStorageService: LocalStorageService, 
    private router: Router) {}

  get Token() {
    return this.localStorageService.get('token');
  }

  set Token(token: string) {
    this.localStorageService.set('token', token);
  }

  get Menu() {
    return this.localStorageService.get('menu')
  }

  set Menu(menu: any) {
    this.localStorageService.set('menu', menu)
  }

  get User() {
    return this.localStorageService.get('currentUser')
  }

  set User(user: any) {
    this.localStorageService.set('currentUser', user);
    this.localStorageService.setObject('gridSettingsModel', null);
    this.localStorageService.setObject('page', null);
    this.localStorageService.setObject('tarefahistorico', null);
  }

  get Role() {
    return this.localStorageService.get('role')
  }

  set Role(role: any) {
    this.localStorageService.set('role', role)
  }

  get Funcionalidades() {
    return this.localStorageService.get('funcionalidades')
  }

  set Funcionalidades(funcionalidades: any) {
    this.localStorageService.set('funcionalidades', funcionalidades)
  }

  public estaLogado(url: string): boolean {
    if (!this.Token) {
      return false;
    }
    try {
      const jwtHelper = new JwtHelper();
      const decodedToken = jwtHelper.decodeToken(this.Token);
      if (decodedToken !== null && 
          typeof decodedToken === 'object' && 
          !this.tokenExpirado() && 
          this.acessoFuncionalidade(url)) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  public get usuarioAutenticado() {

    if (this.authenticatedUser) {
      return this.authenticatedUser;
    }

    // this.estaLogado();
    return this.authenticatedUser;
  }

  public tokenExpirado() {
    if (this.Token) {
      const jwtHelper = new JwtHelper();
      return jwtHelper.isTokenExpired(this.Token);
    }
    return true;
  }

  public tempoExpiracao(): Date {
    const token = this.Token;
    const jwtHelper = new JwtHelper();
    return jwtHelper.getTokenExpirationDate(token);
  }

  public logout() {
    this.localStorageService.clear();
    this.router.navigate(['/login']);
  }

  acessoFuncionalidade(url: string) {
    let first = url.split('/')[1];
    let func = url.replace('/', '')
    if(first == 'relatorio')
      return true
    else if(first != 'dominio')
      func = first      
    let funcs = JSON.parse(this.Funcionalidades)
    if(!funcs.find(f => f.descricao == func))
      return false
    return true
  }

  refreshToken(token) {
    this.localStorageService.remove('token');
    this.Token = token;
  }

  possuiRole(role: string) {
    return _.includes(this.authenticatedUser.role, role);
  }

  possuiUmaDasRoles(roles: Array<string>): boolean {
    return roles.some((r, i, arr) => this.possuiRole(r));
  }

  // possuiObjeto(obj: string) {
  //   return _.includes(this.authenticatedUser.objetos, obj);
  // }

  // possuiUmDosObjetos(objs: Array<string>): boolean {
  //   return objs.some((r, i, arr) => this.possuiObjeto(r));
  // }
}
