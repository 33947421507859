import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CredenciaisService } from '../../../core/credenciais.service';

@Injectable()
export class VisualizaAssinaturaService {

  private apiUrl = '';

  constructor(private http: HttpClient,
    public credenciaisService: CredenciaisService,) {
    this.apiUrl = environment.backendUrl;
  }


  solicitarAssinatura(solicitacaoId: number) {
    return this.http.get<any>(`${this.apiUrl}/SolicitacaoDespesa/GetAssinatura/` + solicitacaoId);
  }  

}
