import {
    Directive,
    ElementRef,
    Input,
    HostListener,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { GridSettings, Filter, Rule } from '../../models/GridSettings';
import { GROUP_OPERATION_FILTER } from '../../models/enums/group-operation-enum';
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[gridSettings]'
})
export class GridSettingsDirective implements OnChanges {
    private group = GROUP_OPERATION_FILTER;
    public el: HTMLElement;
    constructor(public element: ElementRef) {
        this.el = this.element.nativeElement;
    }

    @Input() public model: GridSettings;
    @Input() public field: string;
    @Input() public op: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.input) {

        }
    }

    @HostListener('ngModelChange', ['$event'])
    changeEvent(event: any) {
        //console.log('changeEvent', event);
        // this.el.tagName === 'MAT-SELECT'
        let data = !isNaN(event) || typeof event === 'string' || event instanceof String ? event : (<HTMLInputElement>this.el).value;
        if (data && typeof data === 'string') {
            data = data.trim();
        }
        const rule = new Rule(this.field, this.op, data);
        this.model.page = 1;
        if (!this.model.filters) {
            this.model.filters = new Filter(this.group.and, [rule]);
        } else {
            this.checkRules(this.model.filters.rules, rule);
            // TODO: verificar necessidade disso
            // if (this.model.filters.groups && this.model.filters.groups.length) {
            //     this.model.filters.groups.map(f => this.checkRules(f.rules, rule));
            // } else {
            //     this.checkRules(this.model.filters.rules, rule);
            // }
        }
    }

    private checkRules(rules: Rule[], rule: Rule) {
      const criteria = (x: Rule) => x.field === rule.field && x.op === rule.op;
      if (!rules.some(criteria)) {
        if (rule.data !== '' && rule.data !== undefined)
          rules.push(rule);
      } else if (rule.data === '' || rule.data === undefined) {
        rules.forEach((x, i) => {
          if (x.field === rule.field && x.op === rule.op) {
            rules.splice(i, 1);
          }
        });
      } else {
          rules.filter(criteria).map(x => x.data = rule.data);
      }
  }

    // @HostListener('window:keyup', ['$event'])
    // keyEvent(event: KeyboardEvent) {
    // }
}

