import { Time } from "@angular/common";
import { BaseModel } from "../base-model";
import { Dominio } from "./Dominio";
import { AgendamentoDiz } from "./agendamento-diz";
import { PessoaFisica } from "./pessoa-fisica";

export class AgendamentoDizListaPresenca extends BaseModel {

  constructor(
      public id: number,
      public cpf: string,
      public nome: string,
      public email: string,
      public instituicao: string,
      public telefone: string,
      public assinatura: string,
      public dataAssinatura: Date,
      public dataEnvioEmail: Date,
      public envioEmail: boolean,
      
      public agendamentoDizId: number,
      public agendamentoDiz: AgendamentoDiz,

  ) { super(); }

}
