import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[appCepMask]' })
export class MaskCepDirective {
  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }


  onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, '');
    //console.log('newVal.length: ', newVal.length);

    if (backspace && newVal.length <= 10) {
      //console.log('1');
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    }
    else if (newVal.length <= 3) {
      //console.log('<= 8');
      newVal = newVal.replace(/^(\d{0,2})/, '$1.');
    }
    else if (newVal.length <= 6) {
      //console.log('>= 9');
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1.$2');
    }
    else if (newVal.length <= 10) {
      //console.log('<= 14');
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})/, '$1.$2-$3');
    }
    else {
      //console.log('newVal.substring(0, 14)');
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})/, '$1.$2-$3');
    }
    this.ngControl.valueAccessor.writeValue(newVal);
    //console.log("onInputChange: ", newVal)
  }

}
