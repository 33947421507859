import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][numberTwoDecMask]',
})
export class MaskNumberTwoDecDirective implements OnInit {

  constructor(public ngControl: NgControl) { }

  ngOnInit() {
    ////console.log('directive initialized')
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, "");
    if (backspace && newVal.length <= 10) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
      ////console.log("numero-replace:",newVal.replace('.','').substring(0, 3));
      ////console.log("newVal: ",newVal);      
    
      if(newVal.replace('.','').substring(0, 3) == 100)
      {
        newVal = newVal.substring(0, 3);
      }
      else
      {
        if (newVal.length === 0) {
          newVal = "";
        } else if (newVal.length <= 2) {
          ////console.log("numero <= 2: ",newVal);
          newVal = newVal.replace(/^(\d{02})/, "$1");
        } else if (newVal.length <= 3) {
          ////console.log("numero <= 3: ",newVal);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1.$2');
        } else if (newVal.length <= 4) {
          ////console.log("numero <= 4: ",newVal);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1.$2');
        } else if (newVal.length <= 5) {
          ////console.log("numero <= 5: ",newVal);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1.$2');
        } else if (newVal.length <= 6) {
          ////console.log("numero <= 6: ",newVal);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,4})/, '$1.$2');
        } else if (newVal.length <= 7) {
          ////console.log("numero <= 7: ",newVal);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '$1.$2');
        } else if (newVal.length <= 8) {
          ////console.log("numero <= 7: ",newVal);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,6})/, '$1.$2');
        } else {
          //newVal = newVal;
          newVal = newVal.substring(0, 4);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,1})/, "$1.$2");
        }
      }

      this.ngControl.valueAccessor.writeValue(newVal);// + " %"
          ////console.log("numero:",newVal);    
    // //console.log(this.toNumber(newVal))
  }
}