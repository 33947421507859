import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'agropolos-card-header',
  templateUrl: './agropolos-card-header.component.html',
  styleUrls: ['./agropolos-card-header.component.scss']
})
export class AgropolosCardHeaderComponent implements OnInit {

  @Input() color = '';

  constructor() { }

  ngOnInit() {
  }

}
