import { NgModule } from '@angular/core';

import { AgropolosMaterialModule } from '../../agropolos-material.module';
import { MaterialModulos } from '../../../material-modulos.module';
import { VideoManualModalComponent } from './video-manual-modal.component';
import { VideoManualModalService } from './video-manual-modal.service';
import { VideoManualModalRoutingModule } from './video-manual-modal-routing.module';


@NgModule({
  imports: [AgropolosMaterialModule, VideoManualModalRoutingModule, MaterialModulos],
  declarations: [VideoManualModalComponent],
  exports: [VideoManualModalComponent],
  entryComponents: [VideoManualModalComponent],
  providers: [VideoManualModalService]
})
export class VideoManualModalModule { }
