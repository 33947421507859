import { LocalStorageService } from '../../../../core/local-storage.service';
import { AgropolosMatContainerService } from '../../../components/agropolos-container/agropolos-mat-container.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'agropolos-toolbar-themes',
  templateUrl: './agropolos-mat-toolbar-themes.component.html',
  styleUrls: ['./agropolos-mat-toolbar-themes.component.scss']
})
export class AgropolosMatToolbarThemesComponent implements OnInit {

  color = 0;
  private themes: Array<string> = ['agropolos-material-theme', 'agropolos-material-dark-theme'];

  constructor(private sepContainerService: AgropolosMatContainerService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.color = this.localStorageService.getObject('theme-app');
    this.color = !this.color ? this.color = 0 : this.color;
    this.sepContainerService.defineTheme(this.themes[this.color]);
  }

  onChangeColor(color: number) {
    this.color = color;
    this.sepContainerService.defineTheme(this.themes[color]);
    this.localStorageService.setObject('theme-app', color);
  }

}
