import { BaseModel } from "../models/base-model";
import { Paginacao } from "../models/paginacao";
import { Observable } from "rxjs";
import { Injectable, Inject } from "@angular/core";
import { HttpBaseService } from "./http-base.service";
import { GridSettings } from "../models/GridSettings";
import { loggar } from "../constantes";
import { stringify } from "querystring";
import { map, tap } from 'rxjs/operators';
import { Dominio } from "../models/integra/Dominio";
import { IDominioForm } from "../models/viewModels/interfaces/dominio/i-dominio-form";

export interface RestfulModel<T extends BaseModel> {
  model?: T;
  numberOfPage: number;
  registriesPerPage: number;
}

@Injectable()
export class RestfulService<T extends BaseModel> {
  protected apiUrl: string;

  constructor(
    public http: HttpBaseService,
    @Inject("API_URL_OVERRIDE") private apiUrlOverride: string = null
  ) {
    if (apiUrlOverride) {
      this.apiUrl = this.apiUrlOverride;
    }
  }

  public getByFilter(gridSettings: GridSettings,blockUI = true): Observable<Paginacao<T>> {
     JSON.stringify(gridSettings);
//     //console.log("public getByFilter",gridSettings);
    //  if(gridSettings.filters != undefined)
    //  {
    //   gridSettings.filters.rules.forEach(p => {
    //     //console.log("gridSettings.filters.rules::>>",p);
    //     //console.log("gridSettings.filters.rules::>>",p.data.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    //     //p.data = p.data.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    //   });    
    //  }
    return this.http.post(`/${this.apiUrl}/find`, blockUI, gridSettings);
  }

  public get(id: number, blockUI = true): Observable<T> {
    return this.http.get(`/${this.apiUrl}/${id}`, blockUI);
  }

  public getPaginator(
    retfulModel: RestfulModel<T>,
    blockUI = true,
    tipo: string = ""
  ): Observable<Paginacao<T>> {
    return this.http.get(
      `/${this.apiUrl}?` +
      `${this.getNumberPage(retfulModel.numberOfPage)}&` +
      `${this.getNumberRegistries(retfulModel.registriesPerPage)}`,
      blockUI
    );
  }

  public getByModel(
    retfulModel: RestfulModel<T>,
    blockUI = true,
    tipo = ""
  ): Observable<Paginacao<T>> {
    return this.http.post(
      `/${this.apiUrl}/find${tipo}?` +
      `${this.getNumberPage(retfulModel.numberOfPage)}&` +
      `${this.getNumberRegistries(retfulModel.registriesPerPage)}`,
      blockUI,
      retfulModel.model
    );
  }

  public getAll(blockUI = true, tipo = ""): Observable<T[]> {
    return this.http.get(`/${this.apiUrl}/all${tipo}`, blockUI);
  }

  public getListItens(blockUI = true, tipo = ""): Observable<T[]> {
    return this.http.get(`/${this.apiUrl}/listitens${tipo}`, blockUI);
  }

  public save(model: T, blockUI = true): Observable<T> {
    return this.http.post(`/${this.apiUrl}`, blockUI, model);
  }

  public update(model: T, blockUI = true): Observable<T> {
    return this.http.put(`/${this.apiUrl}`, blockUI, model);
  }

  public remove(id: number, blockUI = true): Observable<T> {
    return this.http.delete(`/${this.apiUrl}/${id}`, blockUI);
  }

  public setApiURL(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  private getNumberPage(numberOfPage: number): string {
    return `page=${numberOfPage ? numberOfPage : 0}`;
  }

  private getNumberRegistries(numberOfRegistries: number): string {
    return `size=${numberOfRegistries ? numberOfRegistries : 15}`;//numero de itens por página
  }

  public getByIdPai(idPai: number, blockUI = true): Observable<T> {
    return this.http.get(`/${this.apiUrl}/getByIdPai/` + idPai, blockUI);
  }

  public postList(model: T[], blockUI = true): Observable<T[]> {
    try {
      return this.http.postList(`/${this.apiUrl}/atualizacaoOrdemListSync`, blockUI, model);
    }
    catch (error) {
      //console.log(error);
    }
  }

  public getModal(id: number, url: string, blockUI = true): Observable<T> {
    return this.http.get(`/${this.apiUrl + url}`+ id, blockUI);
  }
}
