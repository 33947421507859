import { CredenciaisService } from './credenciais.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RolesGuardService implements CanActivate {
  constructor(private router: Router, private credenciaisService: CredenciaisService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    if (!this.checarRoles(route)) {
      this.router.navigate(['/acesso-negado']);
      return false;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checarRoles(route: ActivatedRouteSnapshot) {
    const roles = route.data['roles'];
    if (!roles) {
      return true;
    }
    return this.credenciaisService.possuiUmaDasRoles(roles);
  }

}
