import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, DoCheck, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AgropolosMatContainerService } from './agropolos-mat-container.service';

@Component({
  selector: 'agropolos-container',
  templateUrl: './agropolos-mat-container.component.html',
  styleUrls: ['./agropolos-mat-container.component.scss']
})
export class AgropolosMatContainerComponent implements DoCheck, OnDestroy {

  @Input() environmentName: string;
  @Input() production: boolean;
  @Input() theme = 'agropolos-material-theme';

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private sepContainerService: AgropolosMatContainerService,
    private overlayContainer: OverlayContainer
  ) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngDoCheck(): void {
    this.sepContainerService.emitTheme.subscribe(
      (themeClass) => {
        this.overlayContainer.getContainerElement().classList.remove(this.theme);
        this.theme = themeClass;
        this.overlayContainer.getContainerElement().classList.add(this.theme);
      });

    this.sepContainerService.defineTheme(this.theme);
    this.sepContainerService.defineEnvironment(this.environmentName, this.production);
    this.sepContainerService.defineMatches(this.mobileQuery.matches);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
