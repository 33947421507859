import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, QueryList, ContentChildren, SimpleChange } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { GridSettings } from '../../../../../models/GridSettings';
import { AgropolosGridFilterInput } from '../agropolos-grid-filter-input/agropolos-grid-filter-input';
import { AgropolosGridFilterInputTextComponent } from '../agropolos-grid-filter-input/agropolos-grid-filter-input-text/agropolos-grid-filter-input-text.component';

@Component({
  selector: 'agropolos-grid-filter-column',
  templateUrl: './agropolos-grid-filter-column.component.html',
  styleUrls: ['./agropolos-grid-filter-column.component.scss']
})
export class AgropolosGridFilterColumnComponent implements OnInit, OnDestroy, OnChanges {
  constructor() { }

  // public gridSettingsModel: GridSettings;

  // @ContentChildren(AgropolosGridFilterInput) inputs: QueryList<AgropolosGridFilterInput>;

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.gridSettingsModel = changes.gridSettingsModel.currentValue;
    // this.inputs.map(c => {
    //   c.gridSettingsModel = this.gridSettingsModel;
    //   c.ngOnChanges({
    //     gridSettingsModel: new SimpleChange(null, this.gridSettingsModel, true)
    //   });
    // });
  }
}
