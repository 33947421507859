import { AberturaEditalComponent } from './abertura-edital.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [ { path: 'editalmulheresrurais', component: AberturaEditalComponent } ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AberturaEditalRoutingModule { }
