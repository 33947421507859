import { Injectable, EventEmitter } from '@angular/core';


@Injectable()
export class AgropolosMatContainerService {

  public static DEV : string = "desenvolvimento";
  public static HOM : string = "homologação";
  public static PRD : string = "produção";

  public static DEV_ICON : string = "code";
  public static HOM_ICON : string = "important_devices";

  public environment = {
    icon : undefined,
    name : undefined,
    production: false
  };

  emitEnvironment = new EventEmitter<any>();
  emitMatches = new EventEmitter<boolean>();
  emitTheme = new EventEmitter<string>();
  
  production : boolean = false;
  matches : boolean = true;
 
  constructor() { }

  defineMatches(matches : boolean){
    this.matches = matches;
    this.isMatched();
  }

  defineEnvironment(envName: string, production: boolean){
    this.production = production;
    this.environment.name = envName;
    switch(envName){
      case AgropolosMatContainerService.DEV:
        this.environment.icon = AgropolosMatContainerService.DEV_ICON;
        break;
      case AgropolosMatContainerService.HOM:
        this.environment.icon = AgropolosMatContainerService.HOM_ICON;
        break;
    }

    this.emitEnvironment.emit(this.environment);
  }

  defineTheme(themeClass : string){
    this.emitTheme.emit(themeClass);
  }

  isMatched(){
    this.emitMatches.emit(this.matches);
  }

}
