import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { validateAllFormFields } from '../../../constantes';
import { ShellService } from '../../../core/shell.service';
import { VideoManualModalService } from './video-manual-modal.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'video-manual-modal',
  templateUrl: './video-manual-modal.component.html',
  styleUrls: ['./video-manual-modal.component.scss']
})
export class VideoManualModalComponent implements OnInit {

  public formGroup: FormGroup;
  //public entity: VideoManualModalModel;
  //public mensagem: string;
  //public exibirLoading: boolean;
  //public color: string = "green";
  //public caracteres: string = "[@$!%*?&#]";
  public TituloManual:string="Manual";
  public linkVideo:any;

  constructor(
    private formBuilder: FormBuilder,
    private VideoManualModalService: VideoManualModalService,
    private shellService: ShellService,
    public thisDialogRef: MatDialogRef<VideoManualModalComponent>,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    //console.log("ngOnInit--video", this.data)
    this.TituloManual = this.data.entidade.nome;
    this.linkVideo = this.data.entidade.link;//this._sanitizer.bypassSecurityTrustResourceUrl(this.data.entidade.link);

     this.formGroup = this.formBuilder.group({   
     });
  }

  public handleError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  } 

  public close()
  {
    this.thisDialogRef.close();
  }

}
