import * as strings from '../../assets/strings';
import { CredenciaisService } from './credenciais.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class NavegacaoService {

  constructor(private credenciaisService: CredenciaisService,
    private router: Router) { }


  moduloAtivo(...paths: string[]) {

    let moduloAtivo = false;
    let localUrl = window.location.pathname.split('/');
    localUrl = localUrl.filter(s => s !== strings.sigla);
    paths.forEach(path => {
      let subPath = true;
      const subModulo = `${path}`.split('/');
      for (let index = 0; index < subModulo.length; index++) {
        subPath = subPath && (subModulo.indexOf(localUrl[index]) === index);
      }
      moduloAtivo = subPath;
    });
    return moduloAtivo;

  }

  redirecionar() {
    this.router.navigate(['/principal']);
  }
}
