import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class AgropolosMatSidenavContainerService {

  emitMode = new EventEmitter<string>();
  emitToggle = new EventEmitter<any>();
  isOpen = false;

  constructor() {}

  mode(mode: string) {
    this.emitMode.emit(mode);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.emitToggle.emit(this.isOpen);
  }

}
