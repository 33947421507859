import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'agropolos-sidenav-content-footer',
  templateUrl: './agropolos-mat-sidenav-content-footer.component.html',
  styleUrls: ['./agropolos-mat-sidenav-content-footer.component.scss']
})
export class AgropolosMatSidenavContentFooterComponent implements OnInit {

 
  constructor() { }
  
  ngOnInit() {
  }

}
