import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class AgropolosMatSidenavService {

 
    searchInside(field : any, displayedFieldName : string): any{
        
        if (displayedFieldName == undefined) return false;
        
        let fieldArray = displayedFieldName.split('.');
        let size = fieldArray.length;
        let errorStr = "Não foi possível carregar a lista.\n As possíveis casusas são:\n\t 1- As diretivas [valorItem], [textoItem] ou [checkItem] não são do tipo string.\n\t 2- O Atributo '"+ displayedFieldName +"' não está contido nos filhos de ["+ field +"].";
        switch (size) {
            case 1:
                return field[ displayedFieldName ];
            case 2:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ];
            case 3:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ];
            case 4:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ];
            case 5:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ][ fieldArray[4] ];
            case 6:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ][ fieldArray[4] ][ fieldArray[5] ];
            case 7:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ][ fieldArray[4] ][ fieldArray[5] ][ fieldArray[6] ];
            case 8:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ][ fieldArray[4] ][ fieldArray[5] ][ fieldArray[6] ][ fieldArray[7] ];
            case 9:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ][ fieldArray[4] ][ fieldArray[5] ][ fieldArray[6] ][ fieldArray[7] ][ fieldArray[8] ];
            case 10:
                if (fieldArray.some( (x,index) => (index < (size-1) && (field[x] == undefined)) )){ throw new Error(errorStr)}
                return field[ fieldArray[0] ][ fieldArray[1] ][ fieldArray[2] ][ fieldArray[3] ][ fieldArray[4] ][ fieldArray[5] ][ fieldArray[6] ][ fieldArray[7] ][ fieldArray[8] ][ fieldArray[9] ];
            default:
                break;
        }
        throw new Error("Os atributos [valorItem], [textoItem] ou [checkItem] do componente agropolos-sidenav-selection-list não foi encontrado. " 
                      + "Passe para esses atributos objetos ou atributos de níveis mais rasos [ex: '" + fieldArray[size-1] + "' ou '"  + fieldArray[size-2] + "."  + fieldArray[size-1] + "'].");
    }


}