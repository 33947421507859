export const environment = {
  production: false,
  homologacao: true,
  envName: 'homologação',
  LoginUrl: '',
  backendUrl: `https://devintegrapsjapi.sda.ce.gov.br/api`,
  accessControlUrl: `https://devintegrapsjcau.sda.ce.gov.br/api`,
  resetPassUrl: `https://devintegrapsjcau.sda.ce.gov.br`,
  reportsUrl: `https://devintegrapsjrel.sda.ce.gov.br/api`,
  urlDownloadFile: `https://integrapsjftp.sda.ce.gov.br/integrapsjftp/homologacao/`
};
