import { NgModule } from '@angular/core';
import { MaterialModulos } from '../../../material-modulos.module';
import { AgropolosMaterialModule } from '../../agropolos-material.module';
import { EditalMulherLocalizacaoRoutingModule } from './edital-mulher-localizacao-routing.module';
import { EditalMulherLocalizacaoComponent } from './edital-mulher-localizacao.component';
import { EditalMulherLocalizacaoService } from './edital-mulher-localizacao.service';

@NgModule({
  imports: [AgropolosMaterialModule, EditalMulherLocalizacaoRoutingModule, MaterialModulos],
  declarations: [EditalMulherLocalizacaoComponent],
  exports: [EditalMulherLocalizacaoComponent],
  providers: [EditalMulherLocalizacaoService]
})
export class EditalMulherLocalizacaoModule { }
