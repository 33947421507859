import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListaPresencaEventoComponent } from "./lista-presenca-evento.component";

const routes: Routes = [ 
  { path: 'listapresencaevento/:id', component: ListaPresencaEventoComponent } 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListaPresencaEventoRoutingModule {}
