import { CredenciaisService } from '../../../core/credenciais.service';
import { environment } from '../../../../environments/environment';
import { Observable ,  Subscription, timer } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'agropolos-contador-sessao',
  template: `<span tooltip="Tempo de sessão">{{tempoRestante}}</span>`
})
export class ContadorSessaoComponent implements OnInit {

  tempoRestante: string;
  timer: Observable<any>;
  subscription: Subscription;

  constructor(private credenciaisService: CredenciaisService) { }

  ngOnInit() {
    this.iniciarContador();
  }

  iniciarContador() {
    this.timer = timer(0, 1000);
    this.subscription = this.timer.subscribe(() => this.checarTokenExpirado());
  }

  checarTokenExpirado() {
    if (this.credenciaisService.tokenExpirado()) {
      this.showModalLogoff();
    } else {
      this.tempoRestante = moment.utc(moment(this.credenciaisService.tempoExpiracao()).diff(moment())).format('HH:mm:ss');
    }
  }

  pararContador() {
    if (this.subscription != null) {
      this.tempoRestante = '00:00:00';
      this.subscription.unsubscribe();
    }
  }

  showModalLogoff() {
    this.pararContador();
    setTimeout(() => {
      // para o login
      // this.router.navigate(['/login']);

      // para o guardião
      // window.location.replace(environment.guardiaoLoginUrl);
    }, 2500);
  }
}
