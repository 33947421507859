import * as strings from '../../../../assets/strings';
import { NavegacaoService } from '../../../core/navegacao.service';
import { CredenciaisService } from '../../../core/credenciais.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../core/local-storage.service';
import { MatDialog } from "@angular/material/dialog";
import { VisualizaAssinaturaService } from './viaualizar-assinatura.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './viaualizar-assinatura.component.html',
  styleUrls: ['./viaualizar-assinatura.component.css']
})
export class VisualizaAssinaturaComponent implements OnInit {

  theme: string;
  public id: number;
  public codSolicitacao: string;
  public dscNomeCompleto: string;
  public autorizadoPor: string;
  public dataAutorizacao: string;
  public solicitacaoNaoEcontrada: boolean = false;
  public solicitacaoAutorizada: boolean = false;

  constructor(private visualizaAssinaturaService: VisualizaAssinaturaService,
    public credenciaisService: CredenciaisService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private navegacaoService: NavegacaoService,
    public dialog: MatDialog) {
      this.id = this.route.snapshot.params.id;

    this.theme = (this.localStorageService.getObject('theme-app') === 0 || this.localStorageService.getObject('theme-app') !== undefined
      ? 'agropolos-material-theme' : 'agropolos-material-dark-theme');
  }

  ngOnInit() {
    this.getAssinatura(this.id)
  }


  getAssinatura(solicitacaoId: number) {
    this.visualizaAssinaturaService.solicitarAssinatura(solicitacaoId).subscribe(data => {

      if(data.solicitacaoNaoEcontrada)
          this.solicitacaoNaoEcontrada = true;
      else{
        this.solicitacaoAutorizada = data.solicitacaoAutorizada;
        this.codSolicitacao = data.codSolicitacao;
        this.dscNomeCompleto = data.dscNomeCompleto;
        this.autorizadoPor = data.autorizadoPor;
        this.dataAutorizacao = data.dataAutorizacao;
      }

      
    })
  }

}
